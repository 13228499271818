import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of } from 'rxjs';
import { withLatestFrom, map, mergeMap, catchError, filter, tap } from 'rxjs/operators';
import fileSaver from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';
import * as trnsActions from './transaction.actions';
import * as rptActions from '../report/report.actions';
import * as reducers from '../@core/reducers';
import { RspndrAlarmApi } from '../@core/@api/alarm';
import { RspndrInvoiceApi } from '../@core/@api/invoice';
import { RspndrStatApi } from '../@core/@api/stat';
import { RspndrPage, RspndrStatsRequest, RspndrAlarm } from '../@core/@models/rspndr';

@Injectable()
export class TransactionEffects {
  dataload$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        trnsActions.TRANSACTION_ACTION_TYPES.TRANSACTION_SEARCH,
        trnsActions.TRANSACTION_ACTION_TYPES.TRANSACTION_SET_PAGE,
        trnsActions.TRANSACTION_ACTION_TYPES.TRANSACTION_SET_PAGE_SIZE,
        rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_APPROVED_SUCCESS,
        rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_RESOLVED_BILLABLE_SUCCESS,
        rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_DISPUTED_SUCCESS,
        rptActions.REPORT_PAGE_ACTION_TYPES.ALARM_MC_ESCALATED_SUCCESS,
        rptActions.REPORT_PAGE_ACTION_TYPES.ALARM_MC_REVIEWED_SUCCESS,
      ),
      withLatestFrom(this.store.select(reducers.getTransactionPage)),
      mergeMap(([, page]) => {
        const pageRequest: RspndrPage<RspndrStatsRequest, RspndrAlarm> = {
          ...page,
          data: undefined,
        };
        return this.statsApi.searchAlarm(pageRequest).pipe(
          mergeMap((result) =>
            of(trnsActions.transactionSearchSuccessAction({ response: result })),
          ),
          catchError((error) => {
            this.snackbar.open(
              `Failed to load transaction data: ${error.status} - ${error.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(trnsActions.transactionSearchFailAction({ error }));
          }),
        );
      }),
    ),
  );

  resetPageNumberOnSetAdditionalFilter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(trnsActions.TRANSACTION_ACTION_TYPES.TRANSACTION_FILTER_SET_ADDITIONAL_FILTER),
      map(() => trnsActions.transactionSetPageAction({ pageNumber: 0 })),
    ),
  );

  statsload$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(trnsActions.TRANSACTION_ACTION_TYPES.TRANSACTION_STATS),
      withLatestFrom(this.store.select(reducers.getTransactionStatsRequest)),
      mergeMap(([, request]) =>
        this.statsApi.transactions(request).pipe(
          map((result) => trnsActions.transactionStatsSuccessAction({ stats: result })),
          catchError((error) => of(trnsActions.transactionStatsFailAction({ error }))),
        ),
      ),
    ),
  );

  loadCityList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(trnsActions.TRANSACTION_ACTION_TYPES.TRANSACTION_FILTER_SET_TENANT),
      map(
        (action: ReturnType<typeof trnsActions.transactionFilterSetTenantAction>) =>
          action.tenantId,
      ),
      mergeMap((tenantId) =>
        this.statsApi.countryProvCity(tenantId).pipe(
          mergeMap((result) =>
            of(trnsActions.transactionLoadCityListSuccessAction({ cityList: result })),
          ),
          catchError((error) => of(trnsActions.transactionLoadCityListFailAction({ error }))),
        ),
      ),
    ),
  );

  loadInvoiceList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(trnsActions.TRANSACTION_ACTION_TYPES.TRANSACTION_FILTER_SET_TENANT),
      map(
        (action: ReturnType<typeof trnsActions.transactionFilterSetTenantAction>) =>
          action.tenantId,
      ),
      mergeMap((tenantId) =>
        this.statsApi.invoiceList(tenantId).pipe(
          mergeMap((result) => {
            // append date range description to result
            const invoiceListWithDescription = result.map((invoiceItem) => ({
              ...invoiceItem,
              dateRangeDesc: `${moment(invoiceItem.start).utc().format('MMM D, YYYY')} - ${moment(
                invoiceItem.end,
              )
                .utc()
                .format('MMM D, YYYY')}`,
            }));
            return of(
              trnsActions.transactionLoadInvoiceListSuccessAction({
                invoiceList: invoiceListWithDescription,
              }),
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.open(
              `Error: Unable to load invoice list: ${error.status} - ${error.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(trnsActions.transactionLoadInvoiceListFailAction({ error }));
          }),
        ),
      ),
    ),
  );

  invoicePdf$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(trnsActions.TRANSACTION_ACTION_TYPES.TRANSACTION_INVOICE_PDF),
      withLatestFrom(this.store.select(reducers.getTransactionSelectedInvoice)),
      filter(([, selectedInvoice]) => !!selectedInvoice?.invoiceNumber),
      mergeMap(([, selectedInvoice]) =>
        this.invoiceApi.getInvoicePdf(selectedInvoice.invoiceNumber).pipe(
          tap((res) => fileSaver.saveAs(res, `${selectedInvoice.invoiceNumber}.pdf`)),
          catchError((err) => {
            this.snackbar.open(
              `Failed to download Invoice PDF: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(trnsActions.transactionNoOpAction());
          }),
        ),
      ),
    ),
  );

  invoiceCsv$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trnsActions.TRANSACTION_ACTION_TYPES.TRANSACTION_INVOICE_CSV),
        withLatestFrom(this.store.select(reducers.getTransactionSelectedInvoice)),
        filter(([, selectedInvoice]) => !!selectedInvoice?.invoiceNumber),
        mergeMap(([, selectedInvoice]) =>
          this.invoiceApi.getInvoiceCsv(selectedInvoice.invoiceNumber).pipe(
            tap((res) => fileSaver.saveAs(res, `${selectedInvoice.invoiceNumber}.csv`)),
            catchError((err: HttpErrorResponse) => {
              this.snackbar.open(
                `Failed to download Invoice CSV: ${err.status} - ${err.statusText}`,
                'OK',
                {
                  panelClass: 'snackbar-error',
                },
              );
              return EMPTY;
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  trnxDebugRecInvoice: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trnsActions.TRANSACTION_ACTION_TYPES.TRANSACTION_DEBUG_REC_INVOICE),
        mergeMap(() =>
          this.invoiceApi.triggerReceivableInvoice().pipe(
            tap(() => {
              this.snackbar.open(`Running receivable invoices process.`, undefined, {
                panelClass: 'snackbar-success',
              });
            }),
            catchError((err: HttpErrorResponse) => {
              this.snackbar.open(
                `Failed to run receivable invoices process: ${err.status} - ${err.statusText}`,
                'OK',
                {
                  panelClass: 'snackbar-error',
                },
              );
              return EMPTY;
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  trnxDebugPayInvoice: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trnsActions.TRANSACTION_ACTION_TYPES.TRANSACTION_DEBUG_PAY_INVOICE),
        mergeMap(() =>
          this.invoiceApi.triggerPayableInvoice().pipe(
            tap(() => {
              this.snackbar.open(`Running payable invoices process.`, undefined, {
                panelClass: 'snackbar-success',
              });
            }),
            catchError((err: HttpErrorResponse) => {
              this.snackbar.open(
                `Failed to run payable invoices process: ${err.status} - ${err.statusText}`,
                'OK',
                {
                  panelClass: 'snackbar-error',
                },
              );
              return EMPTY;
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    protected actions$: Actions,
    private store: Store<reducers.State>,
    protected alarmApi: RspndrAlarmApi,
    protected statsApi: RspndrStatApi,
    private invoiceApi: RspndrInvoiceApi,
    private snackbar: MatSnackBar,
  ) {}
}
