import { Injectable } from '@angular/core';
import { Observable, of, interval } from 'rxjs';
import { withLatestFrom, takeUntil, filter, map, tap, mergeMap, catchError } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import * as fileSaver from 'file-saver';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as rptActions from './report.actions';
import * as reducer from '../@core/reducers';
import { RspndrAlarmApi } from '../@core/@api/alarm';
import { RspndrChargeApi } from '../@core/@api/charge';
import { RspndrEventApi } from '../@core/@api/event';
import { RspndrInvoiceApi } from '../@core/@api/invoice';
import { RspndrOrgApi } from '../@core/@api/org';
import { RspndrValue } from '../@core/@models/rspndr';

@Injectable()
export class ReportEffects {
  openReport$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_OPEN),
      map((action) => (action as rptActions.ReportOpenAction).payload),
      mergeMap((alarmId) =>
        this.alarmApi.alarm(alarmId).pipe(
          mergeMap((alarm) => [
            new rptActions.ReportOpenSuccessAction(alarm),
            new rptActions.GetAlarmMCInvoicingModelAction(),
            new rptActions.RefreshAuditAction(),
            new rptActions.RefreshAlarmChargesAction(),
          ]),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to load report details: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(new rptActions.ReportOpenFailAction());
          }),
        ),
      ),
    ),
  );

  note$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.NOTE),
      map((action: rptActions.NoteAction) => action.payload),
      mergeMap((note) =>
        this.eventApi.note(note).pipe(
          mergeMap(() => of(new rptActions.RefreshAlarmAction())),
          catchError((err) => {
            this.snackbar.open(`Failed to submit note: ${err.status} - ${err.statusText}`, 'OK', {
              panelClass: 'snackbar-error',
            });
            return of(new rptActions.NoOpAction());
          }),
        ),
      ),
    ),
  );

  alarmRefreshTimerStart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_OPEN),
      filter(() => !this.alarmRefreshTimer$),
      mergeMap(() => {
        this.alarmRefreshTimer$ = interval(3000).pipe(
          takeUntil(this.actions$.pipe(ofType(rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_CLOSE))),
          map(() => new rptActions.RefreshAlarmAction()),
        );
        return this.alarmRefreshTimer$;
      }),
    ),
  );

  alarmRefreshTimerStop$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_CLOSE),
        tap(() => (this.alarmRefreshTimer$ = undefined)),
        map(() => new rptActions.NoOpAction()),
      ),
    { dispatch: false },
  );

  alarmRefresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.REFRESH_ALARM),
      withLatestFrom(this.store),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
      map(([_, state]) => state.report.alarm),
      mergeMap((alarm) =>
        this.alarmApi.delta(alarm.id, alarm.modifiedAt).pipe(
          filter((response) => !!response),
          mergeMap((response) => [
            new rptActions.RefreshAlarmSuccessAction(response),
            new rptActions.RefreshAuditAction(),
            new rptActions.RefreshAlarmChargesAction(),
          ]),
          catchError((err) => {
            // eslint-disable-next-line no-console
            console.log(JSON.stringify(err));
            return of(new rptActions.NoOpAction());
          }),
        ),
      ),
    ),
  );

  getAlarmMCInvoicingModel$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.GET_ALARM_MC_INVOICING_MODEL),
      withLatestFrom(this.store),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
      map(([_, state]) => state.report.alarm),
      filter((alarm) => !!alarm && !!alarm.billToOrg && !!alarm.billToOrg.tenantId),
      mergeMap((alarm) =>
        this.orgApi.getOrganisationInvoicingModel(alarm.billToOrg.tenantId).pipe(
          map((invoiceModel) => new rptActions.GetAlarmMCInvoicingModelSuccessAction(invoiceModel)),
          catchError(() => of(new rptActions.NoOpAction())),
        ),
      ),
    ),
  );

  audit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.REFRESH_AUDIT),
      withLatestFrom(this.store),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
      map(([_, state]) => state.report.alarm),
      mergeMap((alarm) =>
        this.alarmApi.audit(alarm.id).pipe(
          map((audit) => new rptActions.RefreshAuditSuccessAction(audit)),
          catchError(() => of(new rptActions.NoOpAction())),
        ),
      ),
    ),
  );

  alarmChargesRefresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.REFRESH_ALARM_CHARGES),
      withLatestFrom(this.store),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
      map(([_, state]) => state.report.alarm),
      mergeMap((alarm) =>
        this.chargeApi.getAlarmCharges(alarm.id).pipe(
          map((charges) => new rptActions.RefreshAlarmChargesSuccessAction(charges)),
          catchError(() => of(new rptActions.NoOpAction())),
        ),
      ),
    ),
  );

  alarmMcReviewed$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.ALARM_MC_REVIEWED),
      withLatestFrom(this.store),
      map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
        ([_, state]) =>
          ({
            id: state.report.alarm.id,
            attribute: 'mcReviewed',
            value: state.report.alarm.mcReviewed,
            className: '',
          } as RspndrValue),
      ),
      mergeMap((value) =>
        this.eventApi.review(value).pipe(
          map(() => new rptActions.AlarmMcReviewedSuccessAction()),
          catchError(() => of(new rptActions.NoOpAction())),
        ),
      ),
    ),
  );

  alarmOpsReviewed$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.ALARM_OPS_REVIEWED),
      withLatestFrom(this.store),
      map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
        ([_, state]) =>
          ({
            id: state.report.alarm.id,
            attribute: 'opsReviewed',
            value: state.report.alarm.opsReviewed,
            className: '',
          } as RspndrValue),
      ),
      mergeMap((value) =>
        this.eventApi.review(value).pipe(
          map(() => new rptActions.AlarmOpsReviewedSuccessAction()),
          catchError(() => of(new rptActions.NoOpAction())),
        ),
      ),
    ),
  );

  alarmMcEscalated$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.ALARM_MC_ESCALATED),
      withLatestFrom(this.store),
      map(
        ([action, state]) =>
          ({
            id: state.report.alarm.id,
            value: !state.report.alarm.mcEscalated,
            notes: (action as rptActions.AlarmMcEscalatedAction).payload || '',
          } as RspndrValue),
      ),
      mergeMap((value) =>
        this.eventApi.mcEscalate(value).pipe(
          mergeMap(() => of(new rptActions.AlarmMcEscalatedSuccessAction())),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to modify alarm escalation: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(new rptActions.AlarmMcEscalatedFailAction());
          }),
        ),
      ),
    ),
  );

  billingApproved$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_APPROVED),
      withLatestFrom(this.store),
      map(
        ([, state]) =>
          ({
            id: state.report.alarm.id,
            value:
              state.report.alarm.billingApprovalStatus === 'APPROVED' ? 'NOT_REVIEWED' : 'APPROVED',
          } as RspndrValue),
      ),
      mergeMap((value) =>
        this.eventApi.bilingStatus(value).pipe(
          map(() => new rptActions.BillingApprovedSuccessAction()),
          catchError(() => of(new rptActions.NoOpAction())),
        ),
      ),
    ),
  );

  billingDisputed$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_DISPUTED),
      withLatestFrom(this.store),
      map(
        ([, state]) =>
          ({
            id: state.report.alarm.id,
            value: 'DISPUTED',
          } as RspndrValue),
      ),
      mergeMap((value) =>
        this.eventApi.bilingStatus(value).pipe(
          map(() => new rptActions.BillingDisputedSuccessAction()),
          catchError(() => of(new rptActions.NoOpAction())),
        ),
      ),
    ),
  );

  billingResolvedBillable$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_RESOLVED_BILLABLE),
      withLatestFrom(this.store),
      map(
        ([, state]) =>
          ({
            id: state.report.alarm.id,
            value: 'RESOLVED_BILLABLE',
          } as RspndrValue),
      ),
      mergeMap((value) =>
        this.eventApi.bilingStatus(value).pipe(
          map(() => new rptActions.BillingResolvedBillableSuccessAction()),
          catchError(() => of(new rptActions.NoOpAction())),
        ),
      ),
    ),
  );

  billingResolvedNotBillable$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_RESOLVED_NOT_BILLABLE),
      withLatestFrom(this.store),
      map(
        ([, state]) =>
          ({
            id: state.report.alarm.id,
            value: 'RESOLVED_NOT_BILLABLE',
          } as RspndrValue),
      ),
      mergeMap((value) =>
        this.eventApi.bilingStatus(value).pipe(
          map(() => new rptActions.BillingResolvedNotBillableSuccessAction()),
          catchError(() => of(new rptActions.NoOpAction())),
        ),
      ),
    ),
  );

  openAlarmChargeEditor$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.OPEN_ALARM_CHARGE_EDITOR),
      map((action) => (action as rptActions.OpenAlarmChargeEditorAction).payload),
      filter((charge) => !!charge),
      map((charge) => new rptActions.GetAlarmChargeInvoiceAction(charge)),
    ),
  );

  getAlarmChargeInvoice$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.GET_ALARM_CHARGE_INVOICE),
      map((action) => (action as rptActions.GetAlarmChargeInvoiceAction).payload),
      filter((charge) => !!charge),
      mergeMap((charge) =>
        this.invoiceApi.getInvoice(charge.invoiceNumber).pipe(
          map((response) => new rptActions.GetAlarmChargeInvoiceSuccessAction(response)),
          catchError(() => of(new rptActions.NoOpAction())),
        ),
      ),
    ),
  );

  addAlarmCharge$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.SAVE_ALARM_CHARGE),
      map((action) => (action as rptActions.SaveAlarmChargeAction).payload),
      mergeMap((charge) =>
        this.chargeApi.saveAlarmCharge(charge.id, charge).pipe(
          map(() => new rptActions.SaveAlarmChargeSuccessAction()),
          catchError(() => of(new rptActions.NoOpAction())),
        ),
      ),
    ),
  );

  addAlarmChargeSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.SAVE_ALARM_CHARGE_SUCCESS),
      withLatestFrom(this.store),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
      map(([_, state]) => state.report.alarm),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map((alarm) => new rptActions.RefreshAlarmChargesAction()),
    ),
  );

  reportPdf$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_PDF),
      map((action) => (action as rptActions.ReportPdfAction).payload),
      withLatestFrom(this.store.select(reducer.getReportPageData)),
      mergeMap(([reportLanguage, alarm]) =>
        this.alarmApi.reportPdf(alarm.id, reportLanguage).pipe(
          tap((res) => fileSaver.saveAs(res, `${alarm.internalId}_${reportLanguage}.pdf`)),
          mergeMap(() => of(new rptActions.ReportPdfSuccessAction())),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to download report PDF: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(new rptActions.ReportPdfFailAction(err));
          }),
        ),
      ),
    ),
  );

  protected alarmRefreshTimer$: Observable<rptActions.RefreshAlarmAction> = undefined;

  constructor(
    protected actions$: Actions,
    private store: Store<reducer.State>,
    private eventApi: RspndrEventApi,
    private alarmApi: RspndrAlarmApi,
    private chargeApi: RspndrChargeApi,
    private invoiceApi: RspndrInvoiceApi,
    private orgApi: RspndrOrgApi,
    private translate: TranslateService,
    private snackbar: MatSnackBar,
  ) {}
}
