import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, fromEvent } from 'rxjs';
import { filter, shareReplay, tap, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocaleService } from './@core/@services/locale.service';
import { FirebaseService } from './@core/@services/firebase.service';
import { getAppError, State } from './@core/reducers';
import { RspndrUtil } from './@core/util';
import { deviceAction, loadBrowserPreferencesAction } from './app.actions';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  exit$: Observable<any>;
  error$: Observable<Error>;

  constructor(
    private store: Store<State>,
    private router: Router,
    private snackbar: MatSnackBar,
    private locale: LocaleService,
    private fbService: FirebaseService,
  ) {
    this.locale.loadLocale();

    this.fbService.init();
    this.init();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(loadBrowserPreferencesAction());
    this.store.dispatch(deviceAction());
  }

  init() {
    this.error$ = this.store.select(getAppError).pipe(
      filter((error) => !!error),
      shareReplay(),
    );

    this.exit$ = fromEvent(window, 'beforeunload').pipe(
      // eslint-disable-next-line no-console
      tap(() => console.log('Good bye RSPNDR')),
      shareReplay(),
    );

    this.error$.pipe(takeUntil(this.exit$)).subscribe((error) => {
      this.snackbar.open(RspndrUtil.toString(error), undefined, {
        panelClass: 'snackbar-error',
      });
    });
  }
}
