import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import localforage from 'localforage';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { RspndrAlarmApi } from './@core/@api/alarm';
import { RspndrEventApi } from './@core/@api/event';
import { RspndrOrgApi } from './@core/@api/org';
import { RspndrPortalApi } from './@core/@api/portal';
import { RspndrUserApi } from './@core/@api/user';
import {
  RspndrCredentials,
  RspndrOrganisation,
  RspndrOrganisationFilterCriteria,
  RspndrPage,
} from './@core/@models/rspndr';
import { RspndrAuthService } from './@core/@services/auth.service';
import { LocaleService } from './@core/@services/locale.service';
import { State } from './@core/reducers';
import * as actions from './app.actions';
import * as homeActions from './home/home.actions';
import { RspndrBrowserPreferences } from './tabs/browser-preferences/browser-preferences.component';
import {
  ErrorSnackbarComponent,
  IErrorSnackbarData,
} from './error-snackbar/error-snackbar.component';

@Injectable()
export class AppEffects {
  locale$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.LOCALE),
      map((action: ReturnType<typeof actions.localeAction>) => action.language),
      mergeMap((newLanguage) => {
        if (newLanguage) {
          return this.userApi.registerLocale(newLanguage).pipe(
            mergeMap(() => {
              this.locale.saveLocale(newLanguage);
              this.dialog.closeAll();
              return of(actions.localeSuccessAction());
            }),
            catchError((err: HttpErrorResponse) => {
              this.snackbar.open(
                `Failed to change language: ${err.status} - ${err.statusText}`,
                'OK',
                {
                  panelClass: 'snackbar-error',
                },
              );
              return of(actions.localeFailAction());
            }),
          );
        }
        return of(actions.noOpAction());
      }),
    ),
  );

  device$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.APP_ACTION_TYPES.DEVICE),
        mergeMap(() => this.eventApi.device()),
      ),
    { dispatch: false },
  );

  password$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.PASSWORD),
      map((action: ReturnType<typeof actions.passwordAction>) => action.payload),
      mergeMap((payload) =>
        this.userApi
          .changePassword({
            oldPassword: payload.oldPassword,
            password: payload.newPassword,
          } as RspndrCredentials)
          .pipe(
            mergeMap(() => {
              this.snackbar.open('Password change was successful.', undefined, {
                panelClass: 'snackbar-success',
              });
              this.dialog.closeAll();

              return of(actions.passwordSuccessAction());
            }),
            catchError((err: HttpErrorResponse) => {
              this.snackbar.open(`Error ${err.status}: ${err.error?.message}`, undefined, {
                panelClass: 'snackbar-error',
              });
              return of(actions.passwordFailAction());
            }),
          ),
      ),
    ),
  );

  logout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.LOGOUT),
      mergeMap(() =>
        this.authService.setStatusOffline().pipe(
          mergeMap(() =>
            of(this.oauthService.revokeTokenAndLogout()).pipe(
              mergeMap(() => of(actions.logoutSuccessAction())),
            ),
          ),
          catchError((err: HttpErrorResponse) => {
            this.oauthService.revokeTokenAndLogout();
            this.snackbar.open(
              `Failed to set status to offline: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.logoutSuccessAction());
          }),
        ),
      ),
    ),
  );

  alarmDetail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.ALARM_DETAIL),
      map((action: ReturnType<typeof actions.alarmDetailAction>) => action.alarmId),
      mergeMap((alarmId) =>
        this.alarmApi.alarm(alarmId).pipe(
          mergeMap((response) => of(actions.alarmDetailSuccessAction({ alarmDetail: response }))),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to load alarm detail: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.alarmDetailFailAction());
          }),
        ),
      ),
    ),
  );

  loadOrgLookup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.LOOKUP_LOAD_ORGANISATION),
      mergeMap(() => {
        const page: RspndrPage<RspndrOrganisationFilterCriteria, RspndrOrganisation> = {
          pageNumber: 0,
          pageSize: 10000,
          sortFields: [{ name: 'name', direction: 'ASC' }],
          criteria: {},
        };
        return this.orgApi.search(page).pipe(
          mergeMap((response) =>
            of(
              actions.loadOrganisationsListSuccessAction({
                organisationsList: response.payload.data,
              }),
            ),
          ),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.openFromComponent<ErrorSnackbarComponent, IErrorSnackbarData>(
              ErrorSnackbarComponent,
              {
                data: {
                  action: 'Load Organisation List Data',
                  error,
                },
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.loadOrganisationsListFailAction());
          }),
        );
      }),
    ),
  );

  alarmClearCache$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.ALARM_CACHE_CLEAR),
      map((action: ReturnType<typeof actions.alarmCacheClearAction>) => action.alarmId),
      mergeMap((alarmId) =>
        this.portalApi.clearAlarmCache(alarmId).pipe(
          mergeMap(() => {
            this.snackbar.open('Successfully cleared alarm cache.', undefined, {
              panelClass: 'snackbar-success',
            });
            return of(homeActions.activeAlarmsAction());
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to clear alarm cache: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.noOpAction());
          }),
        ),
      ),
    ),
  );

  alarmRefresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.ALARM_REFRESH),
      map((action: ReturnType<typeof actions.alarmRefreshAction>) => action.alarmInternalId),
      mergeMap((alarmInternalId) =>
        this.portalApi.refreshAlarm(alarmInternalId).pipe(
          mergeMap(() => {
            this.snackbar.open('Successfully refreshed alarm.', undefined, {
              panelClass: 'snackbar-success',
            });
            return of(homeActions.activeAlarmsAction());
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(`Failed to refresh alarm: ${err.status} - ${err.statusText}`, 'OK', {
              panelClass: 'snackbar-error',
            });
            return of(actions.noOpAction());
          }),
        ),
      ),
    ),
  );

  alarmUpdateAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.ALARM_UPDATE_ADDRESS),
      map(
        (action: ReturnType<typeof actions.alarmUpdateAddressAction>) =>
          action.alarmUpdateRequestData,
      ),
      mergeMap((updateData) =>
        this.portalApi.repairAlarmAddress(updateData).pipe(
          mergeMap((response) => {
            this.snackbar.open(
              `Address updated successfully for alarm - ${response[0]?.payload?.internalId}`,
              undefined,
              {
                panelClass: 'snackbar-success',
              },
            );
            this.dialog.closeAll();
            return [actions.alarmUpdateAddressSuccessAction(), homeActions.activeAlarmsAction()];
          }),
          catchError(() => {
            this.snackbar.open('Failed to update the alarm.', 'OK', {
              panelClass: 'snackbar-error',
            });
            return of(actions.alarmUpdateAddressFailAction());
          }),
        ),
      ),
    ),
  );

  alarmAssignGuard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.ALARM_ASSIGN_GUARD),
      map((action: ReturnType<typeof actions.alarmAssignGuardAction>) => [
        action.alarmId,
        action.assignedToUserId,
        action.organisationId,
      ]),
      mergeMap(([alarmId, assignedToUserId, organisationId]) =>
        this.portalApi.assignAlarmToGuard(alarmId, assignedToUserId, organisationId).pipe(
          mergeMap(() => {
            this.snackbar.open(`Successfully assigned alarm to selected guard.`, undefined, {
              panelClass: 'snackbar-success',
            });
            return [actions.alarmAssignGuardSuccessAction(), homeActions.activeAlarmsAction()];
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to assign alarm to selected guard: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.alarmAssignGuardFailAction());
          }),
        ),
      ),
    ),
  );

  alarmGuardReassign$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.ALARM_GUARD_REASSIGN),
      map((action: ReturnType<typeof actions.alarmGuardReassignAction>) => [
        action.alarmId,
        action.assignedToUserId,
        action.organisationId,
      ]),
      mergeMap(([alarmId, assignedToUserId, organisationId]) =>
        this.portalApi.alarmGuardReassign(alarmId, assignedToUserId, organisationId).pipe(
          mergeMap(() => {
            this.snackbar.open(`Successfully re-assigned alarm to selected guard.`, undefined, {
              panelClass: 'snackbar-success',
            });
            return [actions.alarmGuardReassignSuccessAction(), homeActions.activeAlarmsAction()];
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to re-assign alarm to selected guard: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.alarmGuardReassignFailAction());
          }),
        ),
      ),
    ),
  );

  alarmCancel$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.ALARM_CANCEL),
      map((action: ReturnType<typeof actions.alarmCancelAction>) => [action.alarmId, action.note]),
      mergeMap(([alarmId, note]) =>
        this.eventApi.mcCancel(alarmId, note).pipe(
          mergeMap(() => {
            this.snackbar.open('Successfully cancelled the alarm.', undefined, {
              panelClass: 'snackbar-success',
            });
            return of(homeActions.activeAlarmsAction());
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to cancel the alarm: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.noOpAction());
          }),
        ),
      ),
    ),
  );

  alarmFinish$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.ALARM_FINISH),
      map((action: ReturnType<typeof actions.alarmFinishAction>) => action.alarmId),
      mergeMap((alarmId) =>
        this.portalApi.finishAlarm(alarmId).pipe(
          mergeMap(() => {
            this.snackbar.open('Successfully finished alarm.', undefined, {
              panelClass: 'snackbar-success',
            });
            return of(homeActions.activeAlarmsAction());
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(`Failed to finish alarm: ${err.status} - ${err.statusText}`, 'OK', {
              panelClass: 'snackbar-error',
            });
            return of(actions.noOpAction());
          }),
        ),
      ),
    ),
  );

  alarmRepublish$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.ALARM_REPUBLISH),
      map((action: ReturnType<typeof actions.alarmRepublishAction>) => action.alarmId),
      mergeMap((alarmId) =>
        this.portalApi.republishAlarm(alarmId).pipe(
          mergeMap(() => {
            this.snackbar.open('Successfully republished alarm.', undefined, {
              panelClass: 'snackbar-success',
            });
            return of(homeActions.activeAlarmsAction());
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to republish alarm: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.noOpAction());
          }),
        ),
      ),
    ),
  );

  alarmHold$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.ALARM_HOLD),
      map((action: ReturnType<typeof actions.alarmHoldAction>) => action.alarmId),
      mergeMap((alarmId) =>
        this.portalApi.holdAlarm(alarmId).pipe(
          mergeMap(() => {
            this.snackbar.open('Successfully placed alarm on hold.', undefined, {
              panelClass: 'snackbar-success',
            });
            return of(homeActions.activeAlarmsAction());
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to place alarm on hold: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.noOpAction());
          }),
        ),
      ),
    ),
  );

  alarmTakenRevert$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.ALARM_TAKEN_REVERT),
      map((action: ReturnType<typeof actions.alarmTakenRevertAction>) => action.alarmId),
      mergeMap((alarmId) =>
        this.portalApi.alarmTakenRevert(alarmId).pipe(
          mergeMap(() => {
            this.snackbar.open('Successfully reverted alarm status to In Transit.', undefined, {
              panelClass: 'snackbar-success',
            });
            return of(homeActions.activeAlarmsAction());
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to revert the status of the alarm: ${err.status} - ${err.statusText}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.noOpAction());
          }),
        ),
      ),
    ),
  );

  loadBrowserPreferencesFromStorage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.LOAD_BROWSER_PREFERENCES),
      mergeMap(async () => {
        // set default preference values
        const defaultBrowserPrefs: RspndrBrowserPreferences = {
          inactiveBrowserPollingPause: true,
          defaultAlarmNotifications: {
            info: true,
            warn: true,
            error: true,
          },
        };
        const prefsFromStorage = await localforage.getItem<RspndrBrowserPreferences>(
          'BROWSER-PREFERENCES',
        );
        const newBrowserPrefs = {
          ...defaultBrowserPrefs,
          ...prefsFromStorage,
        };
        return actions.loadBrowserPreferencesSuccessAction({ browserPreferences: newBrowserPrefs });
      }),
    ),
  );

  saveBrowserPreferencesToStorage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.SAVE_BROWSER_PREFERENCES),
      map(
        (action: ReturnType<typeof actions.saveBrowserPreferencesAction>) =>
          action.browserPreferences,
      ),
      filter((payload) => !!payload),
      mergeMap(async (payload) => {
        await localforage.setItem('BROWSER-PREFERENCES', payload);
        return actions.loadBrowserPreferencesAction();
      }),
    ),
  );

  uploadSubscriberFile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.APP_ACTION_TYPES.SUBSCRIBER_UPLOAD),
      map((action: ReturnType<typeof actions.subscriberUploadAction>) => action),
      mergeMap((action) =>
        this.alarmApi.uploadSubscriberCsv(action.csvFile, action.tenantId).pipe(
          mergeMap(() => {
            this.snackbar.open('Subscriber file uploaded successfully.', undefined, {
              panelClass: 'snackbar-success',
            });
            action.dialogRef.close();
            return of(actions.subscriberUploadSuccessAction());
          }),
          catchError((err: HttpErrorResponse) => {
            this.snackbar.open(
              `Failed to upload subscriber file: ${err.status} - ${err.error?.message}`,
              'OK',
              {
                panelClass: 'snackbar-error',
              },
            );
            return of(actions.subscriberUploadFailAction({ errorMessage: err.error?.message }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private portalApi: RspndrPortalApi,
    private alarmApi: RspndrAlarmApi,
    private eventApi: RspndrEventApi,
    private orgApi: RspndrOrgApi,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private authService: RspndrAuthService,
    private oauthService: OAuthService,
    private locale: LocaleService,
    private userApi: RspndrUserApi,
  ) {}
}
