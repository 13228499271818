<h1 class="dialog-title" mat-dialog-title translate>Are you still there?</h1>

<div mat-dialog-content class="dialog-content">
  <h2 translate>Close this window to resume your session</h2>
  <p translate>
    Polling for data has stopped after 60 minutes due to user inactivity. Closing this popup will
    resume polling updates of alarm and guards data.
  </p>
</div>

<div mat-dialog-actions class="confirm-actions">
  <button
    mat-raised-button
    color="accent"
    (click)="resumePolling()"
    class="rspndr-button uppercase"
  >
    <span translate>Resume</span>
  </button>
</div>
