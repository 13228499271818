import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RspndrConfig } from '../@models/common';
import {
  AlarmAddressUpdateRequest,
  AlarmAssignOrgUpdateRequest,
  AlarmCompleteRequest,
  AlarmHoldRequest,
  AlarmRepublishRequest,
  AlarmTakenRevertRequest,
  RspndrAlarm,
  RspndrGuard,
  RspndrLockboxMapper,
  RspndrRequest,
  RspndrResponse,
} from '../@models/rspndr';

export interface RspndrActiveAlarmsUpdate {
  activeAlarmIds: string[];
  activeAlarms: RspndrAlarm[];
  lastModifiedAt: number;
}

export interface RspndrGuardsUpdate {
  inactiveGuardUsernames: string[];
  activeGuards: RspndrGuard[];
  lastModifiedAt: number;
}

export interface RspndrGuardsPollData {
  modifiedAt: number;
  usernames?: string[];
}

export interface PageMetadata {
  size?: number;
  number?: number;
  totalElements?: number;
  totalPages?: number;
}

export interface Page<T> {
  content: T[];
  page?: PageMetadata;
}

@Injectable()
export class RspndrPortalApi {
  private readonly basePath = '/api/portal';

  constructor(private config: RspndrConfig, private http: HttpClient) {}

  activeAlarms(modifiedAt: number = 0): Observable<RspndrResponse<RspndrActiveAlarmsUpdate>[]> {
    const url = `${this.config.baseUrl}/api/poll/alarms/active`;
    const data = {
      modifiedAt,
    };
    return this.http.post<RspndrResponse<RspndrActiveAlarmsUpdate>[]>(url, data);
  }

  guards(
    assignedToAlarmUsernames: string[],
    modifiedAt: number = 0,
  ): Observable<RspndrResponse<RspndrGuardsUpdate>[]> {
    const url = `${this.config.baseUrl}/api/poll/guards/active`;
    const data: RspndrGuardsPollData = {
      modifiedAt,
    };
    if (assignedToAlarmUsernames?.length > 0) {
      data.usernames = assignedToAlarmUsernames;
    }
    return this.http.post<RspndrResponse<RspndrGuardsUpdate>[]>(url, data);
  }

  completedAlarms(
    reportsDateFrom?: string,
    reportsDateTo?: string,
    reportsSearchText?: string,
    trimmedVersion = false,
    modifiedAt: number = 0,
    pageSize: number = 25,
    pageNumber: number = 0,
    sortProperty: string = 'createdAt',
    sortDirection: string = 'DESC',
  ): Observable<Page<RspndrAlarm>> {
    const url = `${this.config.baseUrl + this.basePath}/dashboard/completed`;

    let params = new HttpParams();
    if (!!modifiedAt) {
      params = params.append('modifiedAt', modifiedAt.toString());
    }
    if (!!reportsDateFrom) {
      params = params.append('reportsDateFrom', reportsDateFrom.toString()); // in millis
    }
    if (!!reportsDateTo) {
      params = params.append('reportsDateTo', reportsDateTo.toString()); // in millis
    }
    if (!!reportsSearchText) {
      params = params.append('reportsSearchText', reportsSearchText.trim().toLowerCase());
    }
    if (trimmedVersion) {
      params = params.append('version', '2');
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber);
    }
    if (sortProperty) {
      params = params.append('sortProperty', sortProperty);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection);
    }

    return this.http.get<Page<RspndrAlarm>>(url, { params });
  }

  clearCache(): Observable<void> {
    const url = `${this.config.baseUrl + this.basePath}/dashboard/cache/clear`;
    return this.http.get(url).pipe(map(() => undefined));
  }

  clearGuardCache(username: string): Observable<void> {
    const url = `${this.config.baseUrl + this.basePath}/dashboard/cache/guards/${username}/evict`;
    return this.http.get(url).pipe(map(() => undefined));
  }

  clearAlarmCache(alarmId: string): Observable<void> {
    const url = `${this.config.baseUrl + this.basePath}/dashboard/cache/alarms/${alarmId}/evict`;
    return this.http.get(url).pipe(map(() => undefined));
  }

  refreshAlarm(alarmId: string): Observable<void> {
    const url = `${this.config.baseUrl}/v1/incident/assign`;
    return this.http
      .post(url, {
        internalId: alarmId,
      })
      .pipe(map(() => undefined));
  }

  finishAlarm(alarmId: string): Observable<void> {
    const url = `${this.config.baseUrl}/api/events`;
    return this.http
      .post(url, {
        event: 'ALARM_COMPLETE',
        payload: {
          alarmId,
        } as AlarmCompleteRequest,
      })
      .pipe(map(() => undefined));
  }

  republishAlarm(alarmId: string): Observable<void> {
    const url = `${this.config.baseUrl}/api/events`;
    return this.http
      .post(url, {
        event: 'ALARM_REPUBLISH',
        payload: {
          alarmId,
        } as AlarmRepublishRequest,
      })
      .pipe(map(() => undefined));
  }

  holdAlarm(alarmId: string): Observable<void> {
    const url = `${this.config.baseUrl}/api/events`;
    return this.http
      .post(url, {
        event: 'ALARM_HOLD',
        payload: {
          alarmId,
        } as AlarmHoldRequest,
      })
      .pipe(map(() => undefined));
  }

  alarmTakenRevert(alarmId: string) {
    const url = `${this.config.baseUrl}/api/events`;
    return this.http
      .post(url, {
        event: 'ALARM_TAKEN_REVERT',
        payload: {
          alarmId,
        } as AlarmTakenRevertRequest,
      } as RspndrRequest)
      .pipe(map(() => undefined));
  }

  assignAlarmToGuard(
    alarmId: string,
    assignedToUserId: string,
    organisationId: string,
  ): Observable<[RspndrResponse<RspndrGuard>, RspndrResponse<RspndrAlarm>]> {
    const url = `${this.config.baseUrl}/api/events`;
    return this.http
      .post<[RspndrResponse<RspndrGuard>, RspndrResponse<RspndrAlarm>]>(url, {
        event: 'ALARM_ASSIGN',
        payload: {
          alarmId,
          assignedToUserId,
          organisationId,
        } as AlarmAssignOrgUpdateRequest,
      })
      .pipe(map(() => undefined));
  }

  alarmGuardReassign(
    alarmId: string,
    assignedToUserId: string,
    organisationId: string,
  ): Observable<void> {
    const url = `${this.config.baseUrl}/api/events`;
    return this.http
      .post(url, {
        event: 'ALARM_GUARD_REASSIGN',
        payload: {
          alarmId,
          assignedToUserId,
          organisationId,
        } as AlarmAssignOrgUpdateRequest,
      })
      .pipe(map(() => undefined));
  }

  repairAlarmAddress(data: AlarmAddressUpdateRequest): Observable<any> {
    const url = `${this.config.baseUrl}/api/events`;
    return this.http.post(url, {
      event: 'ALARM_ADDRESS_UPDATE',
      payload: {
        alarmId: data.alarmId,
        asset: data.asset,
      },
    });
  }

  lockbox(tenantId: string): Observable<RspndrLockboxMapper[]> {
    const url = `${this.config.baseUrl}/api/lockbox/tenant/serials?tenantId=${tenantId}`;
    return this.http.get<RspndrLockboxMapper[]>(url);
  }

  lockboxCode(code: string): Observable<string> {
    const url = `${this.config.baseUrl}/api/lockbox/lock?code=${code}`;
    return this.http.get<string>(url);
  }
}
