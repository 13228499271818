/* eslint-disable @typescript-eslint/dot-notation */

import { v4 as uuidv4 } from 'uuid';
import { RspndrAsset, RspndrPosition, RspndrDateTime } from './@models/rspndr';

export class RspndrUtil {
  private static typeCache: { [label: string]: boolean } = {};

  /**
   * This function coerces a string into a string literal type.
   * Using tagged union types in TypeScript 2.0, this enables
   * powerful typechecking of our reducers.
   *
   * Since every action label passes through this function it
   * is a good place to ensure all of our action labels
   * are unique.
   */
  static type<T>(label: T | ''): T {
    // TODO: find out why this throws an NPE!

    // if (RspndrUtil.typeCache[<string>label]) {
    //   throw new Error(`Action type "${label}" is not unique"`);
    // }

    // RspndrUtil.typeCache[<string>label] = true;

    return label as T;
  }

  static toString(error: Error): string {
    if (error && error['_body']) {
      try {
        const e = JSON.parse(error['_body']);

        if (e['error_description']) {
          return e['error_description'];
        }
        if (e['error']) {
          return e['error'];
        }
      } catch (err) {
        // ignore
      }
    }

    return error ? error.toString() : 'Unknown error';
  }

  /**
   * Correctly formats an address with or without an address2
   *
   * @param asset The asset from which we are getting the address info
   * @param separator This is defaulted to ', ' which will separate the address1/address2/city section from the state/postal code section.
   * It can be used to insert a "|" for example instead of ", " if you wish to split the formatted address into separate section strings using a .split('|').
   */
  static assetFormattedAddress(asset: RspndrAsset, separator: string = ','): string {
    let retAddress = '';

    if (!!asset) {
      // Address 1
      retAddress = asset.address1;

      // Address 2
      retAddress += !!asset.address2 ? `, ${asset.address2}` : '';

      // The rest of the address
      retAddress += `, ${asset.city}${separator} ${asset.state} ${asset.postalCode}`;
    }

    return retAddress;
  }

  static toPosition(lat: number, lng: number, username: string = undefined): RspndrPosition {
    const position = {
      id: uuidv4(),
      username,
      altitude: 0,
      altitudeAccuracy: 0,
      speed: 0,
      bearing: 0,
      location: [lat, lng],
      createdAt: parseInt(`${new Date().getTime()}`, 10),
      timestamp: parseInt(`${new Date().getTime()}`, 10),
    } as RspndrPosition;

    return position;
  }

  static withinDateRange(
    targetDate: RspndrDateTime,
    rangeStartDate: Date,
    rangeEndDate: Date,
  ): boolean {
    const targetNumber = targetDate.year * 10000 + targetDate.month * 100 + targetDate.day;
    const rangeStartNumber =
      rangeStartDate.getFullYear() * 10000 +
      rangeStartDate.getMonth() * 100 +
      rangeStartDate.getDate();
    const rangeEndNumber =
      rangeEndDate.getFullYear() * 10000 + rangeEndDate.getMonth() * 100 + rangeEndDate.getDate();
    return rangeStartNumber <= targetNumber && targetNumber <= rangeEndNumber;
  }
}
