export const environment = {
  production: false,
  name: 'staging',
  baseUrl: 'https://api.staging.rspndr.io',
  invoiceUrl: 'https://api-staging.invoice.rspndr.io',
  keycloakBaseUrl: 'https://auth.staging.rspndr.io',
  keycloakRealm: 'rspndr',
  keycloakClientId: 'rspndr-portal',
  googleMapsApi: 'AIzaSyC1Wff8gNj9Gv_mkk-dnvSpVRlgbLUH6Ns',
  debug: false,
  mapId: '600ea6996fb42977',
};
