import moment from 'moment';
import { createReducer, on } from '@ngrx/store';
import * as trnsAction from './transaction.actions';
import { RspndrAlarm, RspndrStatsRequest, RspndrPage, RspndrStats } from '../@core/@models/rspndr';
import {
  RspndrPresetDateRangeType,
  RSPNDR_CITY_LIST_ITEM,
  RSPNDR_INVOICE_LIST_ITEM,
} from '../@core/@models/constants';

export interface TransactionState {
  statsRequest: RspndrStatsRequest;
  page: RspndrPage<RspndrStatsRequest, RspndrAlarm>;
  stats: RspndrStats;
  selectedAlarmId: string;
  selectedInvoice: RSPNDR_INVOICE_LIST_ITEM;
  filterChanged: boolean;
  loading: boolean;
  listLoading: boolean;
  error: Error;
  presetDateRange: RspndrPresetDateRangeType;
  dateRangeDesc: string;
  cityList: RSPNDR_CITY_LIST_ITEM[];
  selectedProvince: string;
  invoiceList: RSPNDR_INVOICE_LIST_ITEM[];
}

export const initialState: TransactionState = {
  statsRequest: {},
  stats: undefined,
  selectedAlarmId: undefined,
  selectedInvoice: undefined,
  filterChanged: true,
  loading: false,
  listLoading: false,
  error: undefined,
  presetDateRange: 'TODAY',
  dateRangeDesc: '',
  page: {
    criteria: undefined,
    data: [],
    pageNumber: 0,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
    sortFields: [],
  },
  cityList: [],
  selectedProvince: undefined,
  invoiceList: [],
};

const getClearedFilterState = (state: TransactionState): Partial<TransactionState> => ({
  filterChanged: true,
  error: undefined,
  stats: undefined,
  selectedAlarmId: undefined,
  selectedInvoice: undefined,
  page: {
    ...state.page,
    criteria: undefined,
    pageNumber: 0,
    totalItems: 0,
    totalPages: 0,
  },
});

const calcStartDate = (presetName: RspndrPresetDateRangeType): string => {
  let retVal = '';

  switch (presetName) {
    case 'TODAY': {
      retVal = moment().startOf('day').format('YYYY-MM-DD');
      break;
    }
    case 'THIS_WEEK': {
      retVal = moment().startOf('isoWeek').format('YYYY-MM-DD');
      break;
    }
    case 'LAST_MONTH': {
      retVal = moment().startOf('month').add(-1, 'M').format('YYYY-MM-DD');
      break;
    }
    case 'THIS_YEAR': {
      retVal = moment().startOf('year').format('YYYY-MM-DD');
      break;
    }
    default: {
      retVal = '';
    }
  }
  return retVal;
};

const calcEndDate = (presetName: RspndrPresetDateRangeType): string => {
  let retVal = '';

  switch (presetName) {
    case 'TODAY': {
      retVal = moment().endOf('day').format('YYYY-MM-DD');
      break;
    }
    case 'THIS_WEEK': {
      retVal = moment().endOf('isoWeek').format('YYYY-MM-DD');
      break;
    }
    case 'LAST_MONTH': {
      retVal = moment().startOf('month').add(-1, 'M').endOf('month').format('YYYY-MM-DD');
      break;
    }
    case 'THIS_YEAR': {
      retVal = moment().endOf('year').format('YYYY-MM-DD');
      break;
    }
    default: {
      retVal = '';
    }
  }
  return retVal;
};

const calcDateRangeDesc = (
  start: string,
  end: string,
  presetDate: RspndrPresetDateRangeType,
  translationText: any,
): string => {
  let retVal = '';
  switch (presetDate) {
    case 'NO_PRESET': {
      retVal = `${moment(start).utc().format('MMM D, YYYY')} - ${moment(end)
        .utc()
        .format('MMM D, YYYY')}`;
      break;
    }
    case 'TODAY': {
      retVal = `${translationText['Today ('] + moment().startOf('day').format('MMM D, YYYY')})`;
      break;
    }
    case 'THIS_WEEK': {
      retVal = `${
        translationText['This week ('] + moment().startOf('isoWeek').format('MMM D, YYYY')
      } - ${moment().endOf('isoWeek').format('MMM D, YYYY')})`;
      break;
    }
    case 'LAST_MONTH': {
      retVal = `${
        translationText['Last month ('] + moment().startOf('month').add(-1, 'M').format('MMMM YYYY')
      })`;
      break;
    }
    case 'THIS_YEAR': {
      retVal = `${translationText['This year ('] + moment().startOf('year').format('YYYY')})`;
      break;
    }
    default: {
      retVal = 'Unknown Date';
      break;
    }
  }

  return retVal;
};

export const getStatsRequest = (state: TransactionState) => state.statsRequest;
export const getTransactionStats = (state: TransactionState) => state.stats;
export const getTransactionSelectedAlarmId = (state: TransactionState) => state.selectedAlarmId;
export const getSelectedInvoice = (state: TransactionState) => state.selectedInvoice;
export const getTransactionLoading = (state: TransactionState) => state.loading;
export const getListLoading = (state: TransactionState) => state.listLoading;
export const getTransactionError = (state: TransactionState) => state.error;
export const getTransactionPresetDateRange = (state: TransactionState) => state.presetDateRange;
export const getDateRangeDesc = (state: TransactionState) => state.dateRangeDesc;
export const getTransactionPage = (state: TransactionState) => state.page;
export const getCityList = (state: TransactionState) => state.cityList;
export const getSelectedProv = (state: TransactionState) => state.selectedProvince;
export const getInvoiceList = (state: TransactionState) => state.invoiceList;
export const getFilterChanged = (state: TransactionState) => state.filterChanged;
export const getAdditionalAlarmSearchFilter = (state: TransactionState) =>
  state.statsRequest.additionalAlarmSearchFilter?.[0] || 'ALL';

export const transactionReducerNew = createReducer(
  initialState,
  on(
    trnsAction.transactionLoadCityListSuccessAction,
    (state, { cityList }): TransactionState => ({ ...state, cityList }),
  ),
  on(
    trnsAction.transactionLoadCityListFailAction,
    (state, { error }): TransactionState => ({ ...state, cityList: [], error }),
  ),
  on(
    trnsAction.transactionLoadInvoiceListSuccessAction,
    (state, { invoiceList }): TransactionState => ({ ...state, invoiceList }),
  ),
  on(
    trnsAction.transactionLoadInvoiceListFailAction,
    (state, { error }): TransactionState => ({ ...state, invoiceList: undefined, error }),
  ),
  on(
    trnsAction.transactionSearchAction,
    (state): TransactionState => ({
      ...state,
      listLoading: true,
      selectedAlarmId: undefined,
      error: undefined,
      page: {
        ...state.page,
        criteria: state.statsRequest,
        data: [],
        pageNumber: 0,
        totalItems: 0,
        totalPages: 0,
      },
    }),
  ),
  on(
    trnsAction.transactionSearchSuccessAction,
    (state, { response }): TransactionState => ({
      ...state,
      listLoading: false,
      page: response.payload,
    }),
  ),
  on(
    trnsAction.transactionSearchFailAction,
    (state, { error }): TransactionState => ({
      ...state,
      listLoading: false,
      filterChanged: true,
      selectedAlarmId: undefined,
      error,
    }),
  ),
  on(
    trnsAction.transactionStatsAction,
    (state): TransactionState => ({
      ...state,
      stats: undefined,
      filterChanged: false,
      loading: true,
    }),
  ),
  on(
    trnsAction.transactionStatsSuccessAction,
    (state, { stats }): TransactionState => ({
      ...state,
      stats,
      filterChanged: false,
      loading: false,
    }),
  ),
  on(
    trnsAction.transactionStatsFailAction,
    (state, { error }): TransactionState => ({
      ...state,
      stats: undefined,
      loading: false,
      filterChanged: true,
      error,
    }),
  ),
  on(
    trnsAction.transactionSetPageAction,
    (state, { pageNumber }): TransactionState => ({
      ...state,
      listLoading: true,
      error: undefined,
      page: {
        ...state.page,
        criteria: state.statsRequest,
        pageNumber,
      },
    }),
  ),
  on(
    trnsAction.transactionSetPageSizeAction,
    (state, { pageSize, pageNumber }): TransactionState => ({
      ...state,
      listLoading: true,
      error: undefined,
      page: {
        ...state.page,
        pageNumber,
        pageSize,
      },
    }),
  ),
  on(
    trnsAction.transactionSelectAction,
    (state, { selectedAlarmId }): TransactionState => ({ ...state, selectedAlarmId }),
  ),
  on(
    trnsAction.transactionFilterClearAction,
    (state, { translationText }): TransactionState => ({
      ...state,
      statsRequest: {
        tenantId: state.statsRequest.tenantId,
        start: calcStartDate('TODAY'),
        end: calcEndDate('TODAY'),
        additionalAlarmSearchFilter: ['ALL'],
        search: '',
        incidentNumber: '',
      },
      stats: undefined,
      selectedAlarmId: undefined,
      selectedInvoice: undefined,
      filterChanged: true,
      loading: false,
      error: undefined,
      presetDateRange: 'TODAY',
      dateRangeDesc: calcDateRangeDesc('', '', 'TODAY', translationText),
      page: {
        ...state.page,
        criteria: undefined,
        data: [],
        pageNumber: 0,
        totalItems: 0,
        totalPages: 0,
        sortFields: [],
      },
      selectedProvince: '',
    }),
  ),
  on(
    trnsAction.transactionFilterSetTenantAction,
    (state, { tenantId }): TransactionState =>
      Object.assign({}, state, getClearedFilterState(state), {
        statsRequest: {
          ...state.statsRequest,
          additionalAlarmSearchFilter: ['ALL'],
          tenantId,
        },
      }),
  ),
  on(
    trnsAction.transactionFilterSelectInvoiceAction,
    (state, { value, translationText }): TransactionState => {
      const invoiceNumber = value;
      const selectedInvoice = state.invoiceList.find(
        (invoiceItem) => invoiceItem.invoiceNumber === invoiceNumber,
      );
      return Object.assign({}, state, getClearedFilterState(state), {
        selectedInvoice,
        statsRequest: {
          ...state.statsRequest,
          additionalAlarmSearchFilter: ['ALL'],
          invoice: invoiceNumber,
          incidentNumber: '',
        },
        presetDateRange: 'NO_PRESET',
        dateRangeDesc: calcDateRangeDesc(
          moment(selectedInvoice.start).utc().format('YYYY-MM-DD'),
          moment(selectedInvoice.end).utc().format('YYYY-MM-DD'),
          'NO_PRESET',
          translationText,
        ),
      });
    },
  ),
  on(
    trnsAction.transactionFilterSetDateRangeAction,
    (state, { presetName, dateRange, translationText }): TransactionState => {
      // Pass either a date range object or a preset date selection (TODAY, THIS WEEK, ETC...)
      const startDate = !!dateRange ? dateRange.start : calcStartDate(presetName);
      const endDate = !!dateRange ? dateRange.end : calcEndDate(presetName);
      return Object.assign({}, state, getClearedFilterState(state), {
        statsRequest: {
          ...state.statsRequest,
          additionalAlarmSearchFilter: ['ALL'],
          invoice: '',
          start: startDate,
          end: endDate,
          incidentNumber: '',
        },
        presetDateRange: !!presetName ? presetName : 'NO_PRESET', // We are either passing a preset or a date range so 'NO_PRESET' if a date range is passed.
        dateRangeDesc: calcDateRangeDesc(
          !!dateRange ? dateRange.start : '',
          !!dateRange ? dateRange.end : '',
          !!presetName ? presetName : 'NO_PRESET',
          translationText,
        ),
      });
    },
  ),
  on(
    trnsAction.transactionFilterSelectProvinceAction,
    (state, { selectedProvince }): TransactionState =>
      Object.assign({}, state, getClearedFilterState(state), {
        selectedProvince,
        statsRequest: {
          ...state.statsRequest,
          additionalAlarmSearchFilter: ['ALL'],
          invoice: '',
          state: selectedProvince,
          incidentNumber: '',
        },
      }),
  ),
  on(
    trnsAction.transactionFilterSelectCityAction,
    (state, { city }): TransactionState =>
      Object.assign({}, state, getClearedFilterState(state), {
        statsRequest: {
          ...state.statsRequest,
          additionalAlarmSearchFilter: ['ALL'],
          invoice: '',
          city,
          incidentNumber: '',
        },
      }),
  ),
  on(
    trnsAction.transactionFilterSetFilterTextAction,
    (state, { filterText }): TransactionState =>
      Object.assign({}, state, getClearedFilterState(state), {
        statsRequest: {
          ...state.statsRequest,
          additionalAlarmSearchFilter: ['ALL'],
          search: filterText,
          incidentNumber: '',
        },
      }),
  ),
  on(
    trnsAction.transactionFilterSetFilterIncidentNumberAction,
    (state, { value, translationText }): TransactionState =>
      Object.assign({}, state, getClearedFilterState(state), {
        statsRequest: {
          ...state.statsRequest,
          additionalAlarmSearchFilter: ['ALL'],
          search: '',
          incidentNumber: value,
          start: calcStartDate('NO_PRESET'),
          end: calcEndDate('NO_PRESET'),
        },
        presetDateRange: 'NO_PRESET',
        dateRangeDesc: calcDateRangeDesc('', '', 'NO_PRESET', translationText),
      }),
  ),
  on(
    trnsAction.transactionFilterSetAdditionalFilterAction,
    (state, { additionalAlarmSearchFilter }): TransactionState => ({
      ...state,
      statsRequest: {
        ...state.statsRequest,
        additionalAlarmSearchFilter: [additionalAlarmSearchFilter],
      },
    }),
  ),
);
