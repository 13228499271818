import { Action } from '@ngrx/store';

import { RspndrUtil } from '../@core/util';
import {
  RspndrAudit,
  RspndrAlarm,
  RspndrInvoicingModel,
  RspndrCharge,
  RspndrInvoice,
  RspndrNote,
} from '../@core/@models/rspndr';

export const REPORT_PAGE_ACTION_TYPES = {
  REPORT_OPEN: RspndrUtil.type('[Report] Open'),
  REPORT_OPEN_SUCCESS: RspndrUtil.type('[Report] Open Success'),
  REPORT_OPEN_FAIL: RspndrUtil.type('[Report] Open Fail'),
  REPORT_CLOSE: RspndrUtil.type('[Report] Close'),
  REPORT_DATA: RspndrUtil.type('[Report] Data'),
  REFRESH_AUDIT: RspndrUtil.type('[Report] Refresh Audit'),
  REFRESH_AUDIT_SUCCESS: RspndrUtil.type('[Report] Refresh Audit Success'),
  NOTE: RspndrUtil.type('[Report] Note'),
  ALARM_MC_REVIEWED: RspndrUtil.type('[Report] Alarm Reviewed'),
  ALARM_MC_REVIEWED_SUCCESS: RspndrUtil.type('[Report] Alarm Reviewed Success'),
  ALARM_OPS_REVIEWED: RspndrUtil.type('[Report] Alarm Ops Reviewed'),
  ALARM_OPS_REVIEWED_SUCCESS: RspndrUtil.type('[Report] Alarm Ops Reviewed Success'),
  ALARM_MC_ESCALATED: RspndrUtil.type('[Report] Alarm Escalated'),
  ALARM_MC_ESCALATED_SUCCESS: RspndrUtil.type('[Report] Alarm Escalated Success'),
  ALARM_MC_ESCALATED_FAIL: RspndrUtil.type('[Report] Alarm Escalated Fail'),
  BILLING_APPROVED: RspndrUtil.type('[Report] Billing Approved'),
  BILLING_APPROVED_SUCCESS: RspndrUtil.type('[Report] Billing Approved Success'),
  BILLING_DISPUTED: RspndrUtil.type('[Report] Billing Disputed'),
  BILLING_DISPUTED_SUCCESS: RspndrUtil.type('[Report] Billing Disputed Success'),
  BILLING_RESOLVED_BILLABLE: RspndrUtil.type('[Report] Billing Resolved Billable'),
  BILLING_RESOLVED_BILLABLE_SUCCESS: RspndrUtil.type('[Report] Billing Resolved Billable Success'),
  BILLING_RESOLVED_NOT_BILLABLE: RspndrUtil.type('[Report] Billing Resolved Not Billable'),
  BILLING_RESOLVED_NOT_BILLABLE_SUCCESS: RspndrUtil.type(
    '[Report] Billing Resolved Not Billable Success',
  ),
  REFRESH_ALARM: RspndrUtil.type('[Report] Refresh Alarm'),
  REFRESH_ALARM_SUCCESS: RspndrUtil.type('[Report] Refresh Alarm Success'),
  GET_ALARM_MC_INVOICING_MODEL: RspndrUtil.type('[Report] Get Alarm MC Invoicing Model'),
  GET_ALARM_MC_INVOICING_MODEL_SUCCESS: RspndrUtil.type(
    '[Report] Get Alarm MC Invoicing Model Success',
  ),
  GET_ALARM_MC_CHARGE_TAX: RspndrUtil.type('[Report] Get Alarm MC Charge Tax'),
  GET_ALARM_MC_CHARGE_TAX_SUCCESS: RspndrUtil.type('[Report] Get Alarm MC Charge Tax Success'),
  GET_ALARM_GC_CHARGE_TAX: RspndrUtil.type('[Report] Get Alarm GC Charge Tax'),
  GET_ALARM_GC_CHARGE_TAX_SUCCESS: RspndrUtil.type('[Report] Get Alarm GC Charge Tax Success'),
  OPEN_ALARM_CHARGE_EDITOR: RspndrUtil.type('[Report] Open Alarm Charge Editor'),
  CLOSE_ALARM_CHARGE_EDITOR: RspndrUtil.type('[Report] Close Alarm Charge Editor'),
  SAVE_ALARM_CHARGE: RspndrUtil.type('[Report] Add / Update Alarm Charge'),
  SAVE_ALARM_CHARGE_SUCCESS: RspndrUtil.type('[Report] Add / Update Alarm Charge Success'),
  REFRESH_ALARM_CHARGES: RspndrUtil.type('[Report] Refresh Alarm Charges'),
  REFRESH_ALARM_CHARGES_SUCCESS: RspndrUtil.type('[Report] Refresh Alarm Charges Success'),
  GET_ALARM_CHARGE_INVOICE: RspndrUtil.type('[Report] Get Alarm Charge Invoice'),
  GET_ALARM_CHARGE_INVOICE_SUCCESS: RspndrUtil.type('[Report] Get Alarm Charge Invoice Success'),
  REPORT_PDF: RspndrUtil.type('[Report] Report PDF'),
  REPORT_PDF_SUCCESS: RspndrUtil.type('[Report] Report PDF Success'),
  REPORT_PDF_FAIL: RspndrUtil.type('[Report] Report PDF Fail'),
  NOOP: RspndrUtil.type('[Report] No Op'),
};

export class ReportDataAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REPORT_DATA;
  constructor(public payload: RspndrAlarm) {}
}

export class RefreshAuditAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REFRESH_AUDIT;
  constructor(public payload?: any) {}
}

export class RefreshAuditSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REFRESH_AUDIT_SUCCESS;
  constructor(public payload: RspndrAudit[]) {}
}

export class ReportOpenAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REPORT_OPEN;
  constructor(public payload: string) {}
}

export class ReportOpenSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REPORT_OPEN_SUCCESS;
  constructor(public payload: RspndrAlarm) {}
}

export class ReportOpenFailAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REPORT_OPEN_FAIL;
  constructor(public payload?: any) {}
}

export class ReportCloseAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REPORT_CLOSE;
  constructor(public payload?: any) {}
}

export class NoteAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.NOTE;
  constructor(public payload: RspndrNote) {}
}

export class AlarmMcReviewedAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.ALARM_MC_REVIEWED;
  constructor(public payload?: any) {}
}

export class AlarmMcReviewedSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.ALARM_MC_REVIEWED_SUCCESS;
  constructor(public payload?: any) {}
}

export class AlarmOpsReviewedAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.ALARM_OPS_REVIEWED;
  constructor(public payload?: any) {}
}

export class AlarmOpsReviewedSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.ALARM_OPS_REVIEWED_SUCCESS;
  constructor(public payload?: any) {}
}

export class AlarmMcEscalatedAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.ALARM_MC_ESCALATED;
  constructor(public payload?: string) {}
}

export class AlarmMcEscalatedSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.ALARM_MC_ESCALATED_SUCCESS;
  constructor(public payload?: any) {}
}

export class AlarmMcEscalatedFailAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.ALARM_MC_ESCALATED_FAIL;
  constructor(public payload?: any) {}
}

export class BillingApprovedAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.BILLING_APPROVED;
  constructor(public payload?: any) {}
}

export class BillingApprovedSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.BILLING_APPROVED_SUCCESS;
  constructor(public payload?: any) {}
}

export class BillingDisputedAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.BILLING_DISPUTED;
  constructor(public payload?: any) {}
}

export class BillingDisputedSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.BILLING_DISPUTED_SUCCESS;
  constructor(public payload?: any) {}
}

export class BillingResolvedBillableAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.BILLING_RESOLVED_BILLABLE;
  constructor(public payload?: any) {}
}

export class BillingResolvedBillableSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.BILLING_RESOLVED_BILLABLE_SUCCESS;
  constructor(public payload?: any) {}
}

export class BillingResolvedNotBillableAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.BILLING_RESOLVED_NOT_BILLABLE;
  constructor(public payload?: any) {}
}

export class BillingResolvedNotBillableSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.BILLING_RESOLVED_NOT_BILLABLE_SUCCESS;
  constructor(public payload?: any) {}
}

export class RefreshAlarmAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REFRESH_ALARM;
  constructor(public payload?: any) {}
}

export class RefreshAlarmSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REFRESH_ALARM_SUCCESS;
  constructor(public payload: RspndrAlarm) {}
}

export class GetAlarmMCInvoicingModelAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.GET_ALARM_MC_INVOICING_MODEL;
  constructor(public payload?: any) {}
}

export class GetAlarmMCInvoicingModelSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.GET_ALARM_MC_INVOICING_MODEL_SUCCESS;
  constructor(public payload: RspndrInvoicingModel) {}
}

export class OpenAlarmChargeEditorAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.OPEN_ALARM_CHARGE_EDITOR;
  constructor(public payload?: RspndrCharge) {}
}

export class CloseAlarmChargeEditorAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.CLOSE_ALARM_CHARGE_EDITOR;
  constructor(public payload?: any) {}
}

export class SaveAlarmChargeAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.SAVE_ALARM_CHARGE;
  constructor(public payload: RspndrCharge) {}
}

export class SaveAlarmChargeSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.SAVE_ALARM_CHARGE_SUCCESS;
  constructor(public payload?: any) {}
}
export class RefreshAlarmChargesAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REFRESH_ALARM_CHARGES;
  constructor(public payload?: any) {}
}

export class RefreshAlarmChargesSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REFRESH_ALARM_CHARGES_SUCCESS;
  constructor(public payload: RspndrCharge[]) {}
}

export class GetAlarmChargeInvoiceAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.GET_ALARM_CHARGE_INVOICE;
  constructor(public payload: RspndrCharge) {}
}

export class GetAlarmChargeInvoiceSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.GET_ALARM_CHARGE_INVOICE_SUCCESS;
  constructor(public payload: RspndrInvoice) {}
}

export class ReportPdfAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REPORT_PDF;

  constructor(public payload: string) {}
}

export class ReportPdfSuccessAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REPORT_PDF_SUCCESS;

  constructor(public payload?: any) {}
}

export class ReportPdfFailAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.REPORT_PDF_FAIL;

  constructor(public payload: Error) {}
}

export class NoOpAction implements Action {
  readonly type = REPORT_PAGE_ACTION_TYPES.NOOP;
  constructor(public payload?: string) {}
}

export type ReportPageActions =
  | ReportOpenAction
  | ReportOpenSuccessAction
  | ReportOpenFailAction
  | ReportCloseAction
  | ReportDataAction
  | RefreshAuditAction
  | RefreshAuditSuccessAction
  | NoteAction
  | AlarmMcReviewedAction
  | AlarmMcReviewedSuccessAction
  | AlarmMcEscalatedAction
  | AlarmMcEscalatedSuccessAction
  | AlarmMcEscalatedFailAction
  | BillingApprovedAction
  | BillingApprovedSuccessAction
  | BillingDisputedAction
  | BillingDisputedSuccessAction
  | BillingResolvedBillableAction
  | BillingResolvedBillableSuccessAction
  | BillingResolvedNotBillableAction
  | BillingResolvedNotBillableSuccessAction
  | RefreshAlarmAction
  | RefreshAlarmSuccessAction
  | GetAlarmMCInvoicingModelAction
  | GetAlarmMCInvoicingModelSuccessAction
  | OpenAlarmChargeEditorAction
  | CloseAlarmChargeEditorAction
  | SaveAlarmChargeAction
  | SaveAlarmChargeSuccessAction
  | RefreshAlarmChargesAction
  | RefreshAlarmChargesSuccessAction
  | GetAlarmChargeInvoiceAction
  | GetAlarmChargeInvoiceSuccessAction
  | ReportPdfAction
  | ReportPdfSuccessAction
  | ReportPdfFailAction
  | NoOpAction
  | AlarmOpsReviewedAction
  | AlarmOpsReviewedSuccessAction;
