import { OAuthResourceServerErrorHandler, OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class OauthErrorHandler implements OAuthResourceServerErrorHandler {
  constructor(private oauthService: OAuthService) {}

  handleError(err: HttpResponse<any>): Observable<any> {
    if (err.status === 401) {
      return from(this.oauthService.refreshToken()).pipe(
        catchError(() => from(this.oauthService.revokeTokenAndLogout())),
      );
    }

    return throwError(() => err);
  }
}
