import {
  RspndrAlarm,
  RspndrAudit,
  RspndrCharge,
  RspndrInvoice,
  RspndrInvoicingModel,
} from '../@core/@models/rspndr';
import * as rptActions from './report.actions';

export interface ReportPageState {
  reportLoading: boolean;
  alarm: RspndrAlarm;
  audit: RspndrAudit[];
  alarmMCInvoicingModel: RspndrInvoicingModel;
  alarmCharges: RspndrCharge[];
  displayAlarmChargeEditor: boolean;
  selectedAlarmCharge: RspndrCharge;
  selectedAlarmChargeInvoice: RspndrInvoice;
  mcReviewUpdateInProgress: boolean;
  opsReviewUpdateInProgress: boolean;
  mcEscalateUpdateInProgress: boolean;
  billingApprovedInProgress: boolean;
  billingDisputedInProgress: boolean;
  billingResolvedBillableInProgress: boolean;
  reportPdfLoading: boolean;
}

export const initialState: ReportPageState = {
  reportLoading: false,
  alarm: undefined,
  audit: undefined,
  alarmMCInvoicingModel: undefined,
  alarmCharges: undefined,
  displayAlarmChargeEditor: false,
  selectedAlarmCharge: undefined,
  selectedAlarmChargeInvoice: undefined,
  mcReviewUpdateInProgress: false,
  opsReviewUpdateInProgress: false,
  mcEscalateUpdateInProgress: false,
  billingApprovedInProgress: false,
  billingDisputedInProgress: false,
  billingResolvedBillableInProgress: false,
  reportPdfLoading: false,
};

// eslint-disable-next-line consistent-return
export function reportPageReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ReportPageState = initialState,
  action: rptActions.ReportPageActions,
): ReportPageState {
  if (!!action) {
    switch (action.type) {
      case rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_DATA: {
        return Object.assign({}, state, {
          alarm: (action as rptActions.ReportDataAction).payload,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_OPEN: {
        return Object.assign({}, state, {
          reportLoading: true,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_OPEN_SUCCESS: {
        return Object.assign({}, state, {
          alarm: action.payload,
          reportLoading: false,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_OPEN_FAIL: {
        return Object.assign({}, state, {
          reportLoading: false,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_CLOSE: {
        return Object.assign({}, state, {
          audit: undefined,
          alarm: undefined,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.REFRESH_ALARM_SUCCESS: {
        return Object.assign({}, state, {
          alarm: (action as rptActions.RefreshAlarmSuccessAction).payload,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.REFRESH_AUDIT_SUCCESS: {
        return Object.assign({}, state, {
          audit: (action as rptActions.RefreshAuditSuccessAction).payload,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.GET_ALARM_MC_INVOICING_MODEL_SUCCESS: {
        return Object.assign({}, state, {
          alarmMCInvoicingModel: (action as rptActions.GetAlarmMCInvoicingModelSuccessAction)
            .payload,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.REFRESH_ALARM_CHARGES_SUCCESS: {
        return Object.assign({}, state, {
          alarmCharges: (action as rptActions.RefreshAlarmChargesSuccessAction).payload,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.SAVE_ALARM_CHARGE_SUCCESS: {
        return Object.assign({}, state, {
          displayAlarmChargeEditor: false,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.OPEN_ALARM_CHARGE_EDITOR: {
        return Object.assign({}, state, {
          displayAlarmChargeEditor: true,
          selectedAlarmCharge: (action as rptActions.OpenAlarmChargeEditorAction)
            .payload as RspndrCharge,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.CLOSE_ALARM_CHARGE_EDITOR: {
        return Object.assign({}, state, {
          displayAlarmChargeEditor: false,
          selectedAlarmCharge: undefined,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.GET_ALARM_CHARGE_INVOICE_SUCCESS: {
        return Object.assign({}, state, {
          selectedAlarmChargeInvoice: (action as rptActions.GetAlarmChargeInvoiceSuccessAction)
            .payload as RspndrInvoice,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.ALARM_MC_REVIEWED: {
        return Object.assign({}, state, {
          mcReviewUpdateInProgress: true,
          alarm: Object.assign({}, state.alarm, {
            mcReviewed: !state.alarm.mcReviewed,
          }),
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.ALARM_MC_REVIEWED_SUCCESS: {
        return Object.assign({}, state, {
          mcReviewUpdateInProgress: false,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.ALARM_OPS_REVIEWED: {
        return Object.assign({}, state, {
          opsReviewUpdateInProgress: true,
          alarm: Object.assign({}, state.alarm, {
            opsReviewed: !state.alarm.opsReviewed,
          }),
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.ALARM_OPS_REVIEWED_SUCCESS: {
        return Object.assign({}, state, {
          opsReviewUpdateInProgress: false,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.ALARM_MC_ESCALATED: {
        return Object.assign({}, state, {
          mcEscalateUpdateInProgress: true,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.ALARM_MC_ESCALATED_SUCCESS: {
        return Object.assign({}, state, {
          alarm: Object.assign({}, state.alarm, {
            mcEscalated: !state.alarm.mcEscalated,
          }),
          mcEscalateUpdateInProgress: false,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.ALARM_MC_ESCALATED_FAIL: {
        return Object.assign({}, state, {
          mcEscalateUpdateInProgress: false,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_APPROVED: {
        return Object.assign({}, state, {
          billingApprovedInProgress: true,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_APPROVED_SUCCESS: {
        return Object.assign({}, state, {
          billingApprovedInProgress: false,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_DISPUTED: {
        return Object.assign({}, state, {
          billingDisputedInProgress: true,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_DISPUTED_SUCCESS: {
        return Object.assign({}, state, {
          billingDisputedInProgress: false,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_RESOLVED_BILLABLE: {
        return Object.assign({}, state, {
          billingResolvedBillableInProgress: true,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.BILLING_RESOLVED_BILLABLE_SUCCESS: {
        return Object.assign({}, state, {
          billingResolvedBillableInProgress: false,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_PDF: {
        return Object.assign({}, state, {
          reportPdfLoading: true,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_PDF_SUCCESS: {
        return Object.assign({}, state, {
          reportPdfLoading: false,
        });
      }

      case rptActions.REPORT_PAGE_ACTION_TYPES.REPORT_PDF_FAIL: {
        return Object.assign({}, state, {
          reportPdfLoading: false,
        });
      }

      default: {
        return Object.assign({}, state);
      }
    }
  }
}

export const getReportLoading = (state: ReportPageState) => state.reportLoading;
export const getReportPageData = (state: ReportPageState) => state.alarm;
export const getReportAudit = (state: ReportPageState) => state.audit;
export const getReportAlarmMCInvoicingModel = (state: ReportPageState) =>
  state.alarmMCInvoicingModel;
export const getReportAlarmCharges = (state: ReportPageState) => state.alarmCharges;
export const getReportDisplayAlarmChargeEditor = (state: ReportPageState) =>
  state.displayAlarmChargeEditor;
export const getReportSelectedAlarmCharge = (state: ReportPageState) => state.selectedAlarmCharge;
export const getReportSelectedAlarmChargeInvoice = (state: ReportPageState) =>
  state.selectedAlarmChargeInvoice;
export const getMcReviewUpdateInProgress = (state: ReportPageState) =>
  state.mcReviewUpdateInProgress;
export const getOpsReviewUpdateInProgress = (state: ReportPageState) =>
  state.opsReviewUpdateInProgress;
export const getMcEscalateUpdateInProgress = (state: ReportPageState) =>
  state.mcEscalateUpdateInProgress;
export const getBillingApprovedInProgress = (state: ReportPageState) =>
  state.billingApprovedInProgress;
export const getBillingDisputedInProgress = (state: ReportPageState) =>
  state.billingDisputedInProgress;
export const getBillingResolvedBillableInProgress = (state: ReportPageState) =>
  state.billingResolvedBillableInProgress; // TODO: rename function
export const getReportPdfLoading = (state: ReportPageState) => state.reportPdfLoading;
