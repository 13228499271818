/* eslint-disable no-invalid-this */

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import moment from 'moment';

import * as actions from './stats.component.actions';
import { RspndrStatApi } from '../@core/@api/stat';

@Injectable()
export class StatsComponentEffects {
  statsUpdate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.STATS_ACTION_TYPES.STATS_UPDATE),
      mergeMap(() =>
        this.statsApi.stats(moment().format('YYYY-MM-DD')).pipe(
          catchError((error) => {
            // eslint-disable-next-line no-console
            console.log(`statsApi.stats failed: ${JSON.stringify(error)}`);
            return undefined;
          }),
        ),
      ),
      map((result) => new actions.StatsUpdateSuccessAction(result)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError((error) => of(new actions.StatsNoOpAction())),
    ),
  );

  constructor(private actions$: Actions, private statsApi: RspndrStatApi) {}
}
