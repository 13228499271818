import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith, filter } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { RspndrConfig } from '../@models/common';
import { RspndrIdentityClaims } from '../@models/security';
import { RspndrRequest } from '../@models/rspndr';

@Injectable({ providedIn: 'root' })
export class RspndrAuthService {
  identityClaims$: Observable<RspndrIdentityClaims>;
  isRootAdmin$: Observable<boolean>;
  canCreateRootAdmin$: Observable<boolean>;
  isInvoiceAdmin$: Observable<boolean>;
  isOutsideOps$: Observable<boolean>;
  isOpsAdmin$: Observable<boolean>;
  isDealer$: Observable<boolean>;
  isDealerAdmin$: Observable<boolean>;
  isDealerOperator$: Observable<boolean>;
  isMC$: Observable<boolean>;
  isMCAdmin$: Observable<boolean>;
  isMCOperator$: Observable<boolean>;
  isGC$: Observable<boolean>;
  isGCAdmin$: Observable<boolean>;
  isGCOperator$: Observable<boolean>;
  isNLOps$: Observable<boolean>;
  isFinance$: Observable<boolean>;
  isHomeUser$: Observable<boolean>;
  isGuardUser$: Observable<boolean>;
  isUserAdmin$: Observable<boolean>;

  logoutEvent: Observable<void>;
  tokenExpiresEvent: Observable<void>;

  constructor(
    private config: RspndrConfig,
    private http: HttpClient,
    private oauthService: OAuthService,
    private router: Router,
  ) {
    // preserve initial navigation path with hash removed
    const preRedirectUrl = window.location.href.split('#')?.[1];
    this.oauthService
      .loadDiscoveryDocumentAndLogin({ customHashFragment: window.location.search })
      .then(() => {
        this.oauthService.setupAutomaticSilentRefresh();

        if (preRedirectUrl) {
          this.router.navigateByUrl(preRedirectUrl);
        } else {
          this.router.navigate(['/']);
        }
      });

    this.identityClaims$ = this.oauthService.events.pipe(
      // retrieve from storage if available
      startWith(this.oauthService.getIdentityClaims() as RspndrIdentityClaims),
      map(() => this.oauthService.getIdentityClaims() as RspndrIdentityClaims),
      filter((claims) => !!claims),
      map((claims) => {
        if (claims?.realm_access?.roles?.length) {
          // prepend ROLES_ to be compatible with legacy RspndrAuthorities types
          const newClaims = { ...claims };
          const roles = newClaims.realm_access.roles.map((role) =>
            !role.startsWith('ROLE_') ? `ROLE_${role}` : role,
          );
          newClaims.realm_access.roles = roles;
          return newClaims;
        }
        return claims;
      }),
      shareReplay(),
    );

    this.isRootAdmin$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_ROOT_ADMIN');
      }),
      shareReplay(),
    );

    this.canCreateRootAdmin$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_CREATE_ROOT_ADMIN');
      }),
      shareReplay(),
    );

    this.isInvoiceAdmin$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_INVOICE_ADMIN');
      }),
      shareReplay(),
    );

    this.isOutsideOps$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_OUTSIDE_OPS');
      }),
      shareReplay(),
    );

    this.isOpsAdmin$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_OPS_ADMIN');
      }),
      shareReplay(),
    );

    this.isDealer$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_DEALER');
      }),
      shareReplay(),
    );

    this.isDealerAdmin$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_DEALER_ADMIN');
      }),
      shareReplay(),
    );

    this.isDealerOperator$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_DEALER_OPERATOR');
      }),
      shareReplay(),
    );

    this.isMC$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_MC');
      }),
      shareReplay(),
    );

    this.isMCAdmin$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_MC_ADMIN');
      }),
      shareReplay(),
    );

    this.isMCOperator$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_MC_OPERATOR');
      }),
      shareReplay(),
    );

    this.isGC$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_GC');
      }),
      shareReplay(),
    );

    this.isGCAdmin$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_GC_ADMIN');
      }),
      shareReplay(),
    );

    this.isGCOperator$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_GC_OPERATOR');
      }),
      shareReplay(),
    );

    this.isNLOps$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return hasValidToken && claims?.realm_access?.roles?.includes('ROLE_NL_OPS');
      }),
      shareReplay(),
    );

    this.isFinance$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return (
          hasValidToken &&
          [
            'ROLE_OUTSIDE_OPS',
            'ROLE_ROOT_ADMIN',
            'ROLE_GC_FINANCE',
            'ROLE_MC_FINANCE',
            'ROLE_DEALER_FINANCE',
            'ROLE_MC_ADMIN',
            'ROLE_DEALER_ADMIN',
            'ROLE_GC_ADMIN',
          ].some((role) => claims?.realm_access?.roles?.includes(role))
        );
      }),
      shareReplay(),
    );

    this.isHomeUser$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return (
          hasValidToken &&
          [
            'ROLE_OUTSIDE_OPS',
            'ROLE_ROOT_ADMIN',
            'ROLE_MC_ADMIN',
            'ROLE_MC_OPERATOR',
            'ROLE_DEALER_ADMIN',
            'ROLE_DEALER_OPERATOR',
            'ROLE_GC_ADMIN',
            'ROLE_GC_OPERATOR',
          ].some((role) => claims?.realm_access?.roles?.includes(role))
        );
      }),
      shareReplay(),
    );

    this.isGuardUser$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return (
          hasValidToken &&
          [
            'ROLE_OUTSIDE_OPS',
            'ROLE_ROOT_ADMIN',
            'ROLE_GC_ADMIN',
            'ROLE_GC_OPERATOR',
            'ROLE_MC_ALL_GUARDS',
          ].some((role) => claims?.realm_access?.roles?.includes(role))
        );
      }),
      shareReplay(),
    );

    this.isUserAdmin$ = this.identityClaims$.pipe(
      map((claims) => {
        const hasValidToken = this.oauthService.hasValidAccessToken();
        return (
          hasValidToken &&
          [
            'ROLE_OUTSIDE_OPS',
            'ROLE_ROOT_ADMIN',
            'ROLE_GC_ADMIN',
            'ROLE_MC_ADMIN',
            'ROLE_DEALER_ADMIN',
            'ROLE_GC_OPERATOR',
          ].some((role) => claims?.realm_access?.roles?.includes(role))
        );
      }),
      shareReplay(),
    );

    this.logoutEvent = this.oauthService.events.pipe(
      filter((event) => event.type === 'logout'),
      map(() => null),
      shareReplay(),
    );

    this.tokenExpiresEvent = this.oauthService.events.pipe(
      filter((event) => event.type === 'token_expires'),
      map(() => null),
      shareReplay(),
    );
  }

  setStatusOffline(): Observable<any> {
    const event = {
      id: uuidv4(),
      event: 'OFFLINE',
    } as RspndrRequest;
    return this.http.post<any>(`${this.config.baseUrl}/api/events`, event);
  }
}
