import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'src/app/@core/reducers';
import { pollingInitialLoadAction } from '../home.actions';

@Component({
  selector: 'app-inactive-warning-dialog',
  templateUrl: './inactive-warning-dialog.component.html',
  styleUrls: ['./inactive-warning-dialog.component.scss'],
})
export class InactiveWarningDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InactiveWarningDialogComponent>,
    private store: Store<State>,
  ) {}

  resumePolling() {
    this.store.dispatch(pollingInitialLoadAction());
    this.dialogRef.close();
  }
}
