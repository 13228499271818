import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class RspndrConfig {
  environment = environment.name;
  navigationApp?: string;
  voiceCommand?: boolean;
  noAck?: boolean;
  ackPath?: string;
  eventsPath?: string;
  baseUrl = environment.baseUrl;
  invoiceUrl = environment.invoiceUrl;
  smsNumber = '';
  authPath = '/oauth/token';
  authCheckPath = '/oauth/check_token';
  clientId = 'rspndr';
  clientSecret = 'rsecret';
  googleApiKey = environment.googleMapsApi;
  debug = !environment.production;
  trace = !environment.production;
  fcmSenderId = '918774598583';
  mapStyles: google.maps.MapTypeStyle[] = [
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#444444',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'all',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'off',
        },
        {
          color: '#d3cccc',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
        {
          color: '#ffffff',
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text',
      stylers: [
        {
          color: '#000000',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          color: '#b1b1b1',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c8c8c8',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          color: '#000000',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
        {
          color: '#df7373',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          weight: 8.47,
        },
        {
          visibility: 'on',
        },
        {
          color: '#000000',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e0e0e0',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
        {
          gamma: 0.0,
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 32,
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          hue: '#ff0000',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#afddd9',
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          saturation: 0,
        },
        {
          lightness: 0,
        },
        {
          gamma: 1.0,
        },
        {
          weight: 1.0,
        },
      ],
    },
  ];
  guardMarkerIcon = {
    url: 'assets/img/guard_marker.svg',
    fillOpacity: 0.8,
    strokeWeight: 2,
    scaledSize: {
      height: 57,
      width: 58,
    },
    size: {
      height: 57,
      width: 58,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
  };
  alarmMarkerIcon = {
    url: 'assets/img/alarm_marker.svg',
    scaledSize: {
      height: 40,
      width: 40,
    },
    labelOrigin: {
      x: 0,
      y: 45,
    },
  };
}
