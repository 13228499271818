import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import localForage from 'localforage';

@Injectable()
export class LocaleService {
  constructor(private translate: TranslateService) {}

  loadLocale() {
    this.translate.setDefaultLang('en');
    const browserLang: string = this.translate.getBrowserLang() || 'en';
    localForage.getItem('locale').then((res: string) => {
      if (res) {
        const currentLocale: string = res === undefined ? browserLang : res;
        this.translate.setDefaultLang(currentLocale);
        this.translate.use(currentLocale);
      } else {
        this.translate.setDefaultLang(browserLang);
        this.translate.use(browserLang);
      }
    });
  }

  saveLocale(localeKey: string) {
    this.translate.setDefaultLang(localeKey);
    this.translate.use(localeKey);
    localForage.setItem('locale', localeKey);
  }

  getLocaleFromStorage(): Promise<string> {
    return localForage.getItem('locale');
  }
}
