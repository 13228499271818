import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, mergeMap, take } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { v4 as uuidv4 } from 'uuid';
import {
  EVENT_NOTE,
  EVENT_REVIEW,
  EVENT_MC_CANCEL,
  EVENT_DEVICE,
  EVENT_MC_ESCALATE,
  EVENT_BILLING_STATUS_CHANGE,
} from '../@models/constants';
import { RspndrConfig } from '../@models/common';
import {
  RspndrEvent,
  RspndrRequest,
  RspndrNote,
  RspndrValue,
  RspndrDevice,
} from '../@models/rspndr';
import packageJson from '../../../../package.json';
import { RspndrAuthService } from '../@services/auth.service';

@Injectable()
export class RspndrEventApi {
  constructor(
    private config: RspndrConfig,
    private http: HttpClient,
    private authService: RspndrAuthService,
  ) {}

  mcCancel(alarmId: string, note: string): Observable<void> {
    return this.request(EVENT_MC_CANCEL, { alarmId, note });
  }

  note(note: RspndrNote): Observable<void> {
    return this.request(EVENT_NOTE, note);
  }

  review(value: RspndrValue): Observable<void> {
    return this.request(EVENT_REVIEW, value); // deprecated
  }

  mcEscalate(value: RspndrValue): Observable<void> {
    return this.request(EVENT_MC_ESCALATE, value);
  }

  bilingStatus(value: RspndrValue): Observable<void> {
    return this.request(EVENT_BILLING_STATUS_CHANGE, value);
  }

  device(): Observable<any> {
    const browserInfo = this.getBrowserInfo();

    return this.authService.identityClaims$.pipe(
      take(1),
      mergeMap((claims) => {
        const device: RspndrDevice = {
          tenantIds: [claims.tenant_id],
          osVersion: browserInfo?.version,
          platform: browserInfo?.name,
          appVersion: packageJson.version,
        };

        Sentry.setContext('portal-user', {
          ...device,
          username: claims.preferred_username,
        });

        return this.request(EVENT_DEVICE, device);
      }),
    );
  }

  request(name: RspndrEvent, payload?: any): Observable<any> {
    const event = {
      id: uuidv4(),
      event: name.toString(),
      payload,
    } as RspndrRequest;
    return this.http.post(`${this.config.baseUrl}/api/events`, event);
  }

  private getBrowserInfo() {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    // eslint-disable-next-line no-cond-assign
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }
}
