import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

export interface IErrorSnackbarData {
  action: string;
  error: HttpErrorResponse;
}

@Component({
  selector: 'app-error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss'],
})
export class ErrorSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: IErrorSnackbarData,
    private snackbarRef: MatSnackBarRef<ErrorSnackbarComponent>,
  ) {}

  close() {
    this.snackbarRef.dismiss();
  }
}
