import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  RspndrCredentials,
  RspndrGuard,
  RspndrGuardFrontendDto,
  UserManagementFilterCriteria,
} from '../@models/rspndr';
import { RspndrConfig } from '../@models/common';
import { Page } from './portal';

@Injectable()
export class RspndrUserApi {
  constructor(private config: RspndrConfig, private http: HttpClient) {}

  getAllUsers(filterCriteria: UserManagementFilterCriteria): Observable<Page<RspndrGuard>> {
    const params = this.parseUserManagementFilterCriteriaToHttpParams(filterCriteria);

    return this.http.get<Page<RspndrGuard>>(
      `${this.config.baseUrl}/internal/portal/users/admin/all`,
      { params },
    );
  }

  getUsersByTenantId(
    tenantId: string,
    filterCriteria: UserManagementFilterCriteria,
  ): Observable<Page<RspndrGuard>> {
    const params = this.parseUserManagementFilterCriteriaToHttpParams(filterCriteria);

    return this.http.get<Page<RspndrGuard>>(
      `${this.config.baseUrl}/internal/portal/users/tenant/${tenantId}`,
      { params },
    );
  }

  getNLOpsUsers(filterCriteria: UserManagementFilterCriteria): Observable<Page<RspndrGuard>> {
    const params = this.parseUserManagementFilterCriteriaToHttpParams(filterCriteria);

    return this.http.get<Page<RspndrGuard>>(`${this.config.baseUrl}/internal/portal/users/nl-ops`, {
      params,
    });
  }

  /*
   * For use with ROLE_GC_OPERATOR only
   * This is an exception endpoint that allows GC Operators to create guards for their own organisation
   *
   */
  createGuard(entity: RspndrGuardFrontendDto, tenantId: string): Observable<RspndrGuard> {
    return this.http.post<RspndrGuard>(
      `${this.config.baseUrl}/internal/portal/users/gc-operator/tenant/${tenantId}/guard`,
      entity,
    );
  }

  /*
   * General User creation endpoint for Admins only.
   */
  createUser(entity: RspndrGuardFrontendDto, tenantId: string): Observable<RspndrGuard> {
    return this.http.post<RspndrGuard>(
      `${this.config.baseUrl}/internal/portal/users/admin/tenant/${tenantId}`,
      entity,
    );
  }

  getUserById(userId: string): Observable<RspndrGuard> {
    return this.http.get<RspndrGuard>(
      `${this.config.baseUrl}/internal/portal/users/user/${userId}`,
    );
  }

  outsideOpsGetGuardById(userId: string): Observable<RspndrGuard> {
    return this.http.get<RspndrGuard>(
      `${this.config.baseUrl}/internal/portal/users/outside-ops-guard/${userId}`,
    );
  }

  nlOpsGetUserById(userId: string): Observable<RspndrGuard> {
    return this.http.get<RspndrGuard>(
      `${this.config.baseUrl}/internal/portal/users/nl-ops-user/${userId}`,
    );
  }

  /*
   * For use with ROLE_GC_OPERATOR only
   * This is an exception endpoint that allows GC Operators to update/edit guards for their own organisation
   *
   */
  updateGuard(entity: RspndrGuardFrontendDto): Observable<RspndrGuard> {
    return this.http.put<RspndrGuard>(
      `${this.config.baseUrl}/internal/portal/users/gc-operator-guard/${entity.id}`,
      entity,
    );
  }

  updateUser(entity: RspndrGuardFrontendDto): Observable<RspndrGuard> {
    return this.http.put<RspndrGuard>(
      `${this.config.baseUrl}/internal/portal/users/admin/user/${entity.id}`,
      entity,
    );
  }

  deleteUser(userId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.config.baseUrl}/internal/portal/users/admin/user/${userId}`,
    );
  }

  registerLocale(locale: string): Observable<void> {
    return this.http.put<void>(
      `${this.config.baseUrl}/internal/portal/users/me/locale/${locale}`,
      null,
    );
  }

  toggleEnableUser(userId: string): Observable<void> {
    return this.http.put<void>(
      `${this.config.baseUrl}/internal/portal/users/admin/user/${userId}/enable/toggle`,
      null,
    );
  }

  logoutUser(userId: string): Observable<void> {
    return this.http.put<void>(
      `${this.config.baseUrl}/internal/portal/users/admin/user/${userId}/logout`,
      null,
    );
  }

  outsideOpsLogoutGuard(userId: string): Observable<void> {
    return this.http.put<void>(
      `${this.config.baseUrl}/internal/portal/users/outside-ops-guard/${userId}/logout`,
      null,
    );
  }

  nlOpsLogoutUser(userId: string): Observable<void> {
    return this.http.put<void>(
      `${this.config.baseUrl}/internal/portal/users/nl-ops-user/${userId}/logout`,
      null,
    );
  }

  gcOperatorLogoutUserForGuardOnly(userId: string): Observable<void> {
    return this.http.put<void>(
      `${this.config.baseUrl}/internal/portal/users/gc-operator-guard/${userId}/logout`,
      null,
    );
  }

  triggerPasswordResetEmail(userId: string): Observable<void> {
    return this.http.put<void>(
      `${this.config.baseUrl}/internal/portal/users/admin/user/${userId}/password/reset`,
      null,
    );
  }

  nlOpsTriggerPasswordResetEmail(userId: string): Observable<void> {
    return this.http.put<void>(
      `${this.config.baseUrl}/internal/portal/users/nl-ops-user/${userId}/password/reset`,
      null,
    );
  }

  gcOperatorsTriggerPasswordResetEmailForGuardOnly(userId: string): Observable<void> {
    return this.http.put<void>(
      `${this.config.baseUrl}/internal/portal/users/gc-operator-guard/${userId}/password/reset`,
      null,
    );
  }

  changePassword(credentials: RspndrCredentials): Observable<any> {
    return this.http.post(`${this.config.baseUrl}/internal/user/password/reset`, credentials);
  }

  private parseUserManagementFilterCriteriaToHttpParams(
    filterCriteria: UserManagementFilterCriteria,
  ): HttpParams {
    let params = new HttpParams();

    if (filterCriteria.pageSize) {
      params = params.set('pageSize', filterCriteria.pageSize);
    }
    if (filterCriteria.pageNumber) {
      params = params.set('pageNumber', filterCriteria.pageNumber);
    }
    if (filterCriteria.sortProperty) {
      params = params.set('sortProperty', filterCriteria.sortProperty);
    }
    if (filterCriteria.sortDirection) {
      params = params.set('sortDirection', filterCriteria.sortDirection);
    }
    if (filterCriteria.searchText) {
      params = params.set('searchText', filterCriteria.searchText);
    }
    if (filterCriteria.status && filterCriteria.status !== 'ALL') {
      params = params.set('status', filterCriteria.status);
    }
    if (filterCriteria.userType && filterCriteria.userType !== 'ALL') {
      params = params.set('userType', filterCriteria.userType);
    }

    return params;
  }
}
