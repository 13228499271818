import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RspndrConfig } from '../@models/common';
import { RspndrIncident, RspndrIncidentResponse } from '../@models/rspndr';

@Injectable()
export class RspndrIntegrationApi {
  constructor(private config: RspndrConfig, private http: HttpClient) {}

  dispatchAlarm(alarm: RspndrIncident): Observable<RspndrIncidentResponse> {
    return this.http.post(`${this.config.baseUrl}/api/internal/alarms`, alarm);
  }

  dispatchPatrolCheck(patrol: RspndrIncident): Observable<RspndrIncidentResponse> {
    return this.http.post(`${this.config.baseUrl}/api/internal/patrol`, patrol);
  }
}
