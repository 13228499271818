import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
})
export class RspndrSvgIconsModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'bell_snooze',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/bell-snooze.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'circle-filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/circle_filled.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'rspndr-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/rspndr.svg'),
    );

    this.matIconRegistry.addSvgIconSetInNamespace(
      'mat_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/material-outline.svg'),
    );
  }
}
