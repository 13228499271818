import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RspndrPatrol } from '../@models/rspndr';
import { RspndrConfig } from '../@models/common';

@Injectable()
export class RspndrPatrolsApi {
  constructor(private config: RspndrConfig, private http: HttpClient) {}

  scheduledPatrols(): Observable<RspndrPatrol[]> {
    return this.http.get<RspndrPatrol[]>(`${this.config.baseUrl}/v2/patrols`);
  }
}
