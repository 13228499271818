import { routerReducer } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import * as fromApp from '../app.reducer';
import * as fromHome from '../home/home.reducer';
import * as fromReport from '../report/report.reducer';
import * as fromTransaction from '../transaction/transaction.reducer';
import * as fromUserManagement from '../usermanagement/usermanagement.reducer';

export interface State {
  app: fromApp.AppState;
  home: fromHome.HomeState;
  report: fromReport.ReportPageState;
  userManagement: fromUserManagement.UserManagementState;
  transaction: fromTransaction.TransactionState;
}

export const reducers = {
  app: fromApp.appReducer,
  home: fromHome.homeReducer,
  report: fromReport.reportPageReducer,
  userManagement: fromUserManagement.userManagementReducer,
  transaction: fromTransaction.transactionReducerNew,
  router: routerReducer,
};

export const defaultState: State = {
  app: fromApp.initialState,
  home: fromHome.initialState,
  report: fromReport.initialState,
  userManagement: fromUserManagement.initialState,
  transaction: fromTransaction.initialState,
};

export const getAppState = (state: State) => state.app;
export const getHomeState = (state: State) => state.home;
export const getReportPageState = (state: State) => state.report;
export const getUserManagementState = (state: State) => state.userManagement;
export const getTransactionState = (state: State) => state.transaction;

// app
export const getAppBrowserPreferences = createSelector(getAppState, fromApp.getBrowserPreferences);
export const getAppError = createSelector(getAppState, fromApp.getError);
export const getAppOrganisationsList = createSelector(getAppState, fromApp.getOrganisationsList);
export const getAppOrganisationsListEnabledOnly = createSelector(
  getAppState,
  fromApp.getOrganisationsListEnabledOnly,
);
export const getAppAlarmDetail = createSelector(getAppState, fromApp.getAlarmDetail);
export const getAppAlarmDetailLoading = createSelector(getAppState, fromApp.getAlarmDetailLoading);
export const getAppUpdateAddressLoading = createSelector(
  getAppState,
  fromApp.getUpdateAddressLoading,
);
export const getAppAssignGuardLoading = createSelector(getAppState, fromApp.getAssignGuardLoading);

export const getAppSubscriberUploadLoading = createSelector(
  getAppState,
  fromApp.getSubscriberUploadLoading,
);

export const getAppSubscriberUploadErrorMessage = createSelector(
  getAppState,
  fromApp.getSubscriberUploadErrorMessage,
);

// home
export const getCompletedAlarms = createSelector(getHomeState, fromHome.getCompletedAlarms);
export const getHomeError = createSelector(getHomeState, fromHome.getError);
export const getHomeExceptionCount = createSelector(getHomeState, fromHome.getExceptionCount);
export const getHomeActiveAlarmsTableLoading = createSelector(
  getHomeState,
  fromHome.getActiveAlarmsTableLoading,
);
export const getHomeActiveAlarmsTableError = createSelector(
  getHomeState,
  fromHome.getActiveAlarmsTableError,
);
export const getHomeActiveAlarms = createSelector(getHomeState, fromHome.getActiveAlarms);
export const getHomeActiveAlarmFilterStatus = createSelector(
  getHomeState,
  fromHome.getActiveAlarmFilterStatus,
);
export const getHomeActiveAlarmFilterText = createSelector(
  getHomeState,
  fromHome.getActiveAlarmFilterText,
);
export const getHomeDispatchAlarmsLoading = createSelector(
  getHomeState,
  fromHome.getDispatchAlarmLoading,
);
export const getHomeCompletedAlarmsLoading = createSelector(
  getHomeState,
  fromHome.getCompletedAlarmsLoading,
);
export const getHomeReportFilterDateRange = createSelector(
  getHomeState,
  fromHome.getReportFilterDateRange,
);
export const getHomeReportFilterText = createSelector(getHomeState, fromHome.getReportFilterText);
export const getHomeReportPageSize = createSelector(getHomeState, fromHome.getReportPageSize);
export const getHomeReportSortProperty = createSelector(
  getHomeState,
  fromHome.getReportSortProperty,
);
export const getHomeReportSortDirection = createSelector(
  getHomeState,
  fromHome.getReportSortDirection,
);
export const getHomeGuardsLastModifiedAt = createSelector(
  getHomeState,
  fromHome.getGuardsLastModifiedAt,
);
export const getHomeGuardsTableLoading = createSelector(
  getHomeState,
  fromHome.getGuardsTableLoading,
);
export const getHomeGuardsTableError = createSelector(getHomeState, fromHome.getGuardsTableError);
export const getHomeGuards = createSelector(getHomeState, fromHome.getGuards);
export const getHomeGuardFilterStatus = createSelector(getHomeState, fromHome.getGuardFilterStatus);
export const getHomeGuardFilterText = createSelector(getHomeState, fromHome.getGuardFilterText);
export const getHomeGuardDetail = createSelector(getHomeState, fromHome.getGuardDetail);
export const getHomeGuardDetailLoading = createSelector(
  getHomeState,
  fromHome.getGuardDetailLoading,
);
export const getHomeSelectedGuard = createSelector(getHomeState, fromHome.getSelectedGuard);
export const getHomeSelectedAlarm = createSelector(getHomeState, fromHome.getSelectedAlarm);
export const getHomeSelectedReportId = createSelector(getHomeState, fromHome.getSelectedReportId);
export const getHomeCurrentTab = createSelector(getHomeState, fromHome.getCurrentTab);
export const getHomeActiveAlarmsLastModifiedAt = createSelector(
  getHomeState,
  fromHome.getActiveAlarmsLastModifiedAt,
);
export const getHomeSubscriberInfo = createSelector(getHomeState, fromHome.getSubscriberInfo);

// report page
export const getReportLoading = createSelector(getReportPageState, fromReport.getReportLoading);
export const getReportPageData = createSelector(getReportPageState, fromReport.getReportPageData);
export const getReportAudit = createSelector(getReportPageState, fromReport.getReportAudit);
export const getReportAlarmMCInvoicingModel = createSelector(
  getReportPageState,
  fromReport.getReportAlarmMCInvoicingModel,
);
export const getReportAlarmCharges = createSelector(
  getReportPageState,
  fromReport.getReportAlarmCharges,
);
export const getReportDisplayAlarmChargeEditor = createSelector(
  getReportPageState,
  fromReport.getReportDisplayAlarmChargeEditor,
);
export const getReportSelectedAlarmCharge = createSelector(
  getReportPageState,
  fromReport.getReportSelectedAlarmCharge,
);
export const getReportSelectedAlarmChargeInvoice = createSelector(
  getReportPageState,
  fromReport.getReportSelectedAlarmChargeInvoice,
);
export const getReportMcReviewUpdateInProgress = createSelector(
  getReportPageState,
  fromReport.getMcReviewUpdateInProgress,
);
export const getReportOpsReviewUpdateInProgress = createSelector(
  getReportPageState,
  fromReport.getOpsReviewUpdateInProgress,
);
export const getReportMcEscalateUpdateInProgress = createSelector(
  getReportPageState,
  fromReport.getMcEscalateUpdateInProgress,
);
export const getReportBillingApprovedInProgress = createSelector(
  getReportPageState,
  fromReport.getBillingApprovedInProgress,
);
export const getReportBillingDisputedInProgress = createSelector(
  getReportPageState,
  fromReport.getBillingDisputedInProgress,
);
export const getReportBillingResolvedBillableInProgress = createSelector(
  getReportPageState,
  fromReport.getBillingResolvedBillableInProgress,
);
export const getReportPdfLoading = createSelector(
  getReportPageState,
  fromReport.getReportPdfLoading,
);

// user management
export const getUserManagementUsersPage = createSelector(
  getUserManagementState,
  fromUserManagement.getUserManagementUsersPage,
);

export const getUserManagementFilterCriteria = createSelector(
  getUserManagementState,
  fromUserManagement.getUserManagementFilterCriteria,
);
export const getUserManagementSelected = createSelector(
  getUserManagementState,
  fromUserManagement.getUserManagementSelected,
);
export const getUserManagementLoading = createSelector(
  getUserManagementState,
  fromUserManagement.getUserManagementLoading,
);
export const getUserManagementError = createSelector(
  getUserManagementState,
  fromUserManagement.getUserManagementError,
);
export const getUserManagementSaveLoading = createSelector(
  getUserManagementState,
  fromUserManagement.getUserManagementSaveLoading,
);

// Transactions
export const getTransactionStatsRequest = createSelector(
  getTransactionState,
  fromTransaction.getStatsRequest,
);
export const getTransactionStats = createSelector(
  getTransactionState,
  fromTransaction.getTransactionStats,
);
export const getTransactionSelectedAlarmId = createSelector(
  getTransactionState,
  fromTransaction.getTransactionSelectedAlarmId,
);
export const getTransactionSelectedInvoice = createSelector(
  getTransactionState,
  fromTransaction.getSelectedInvoice,
);
export const getTransactionLoading = createSelector(
  getTransactionState,
  fromTransaction.getTransactionLoading,
);
export const getTransactionListLoading = createSelector(
  getTransactionState,
  fromTransaction.getListLoading,
);
export const getTransactionError = createSelector(
  getTransactionState,
  fromTransaction.getTransactionError,
);
export const getTransactionPresetDateRange = createSelector(
  getTransactionState,
  fromTransaction.getTransactionPresetDateRange,
);
export const getTransactionDateRangeDesc = createSelector(
  getTransactionState,
  fromTransaction.getDateRangeDesc,
);
export const getTransactionPage = createSelector(
  getTransactionState,
  fromTransaction.getTransactionPage,
);
export const getTransactionCityList = createSelector(
  getTransactionState,
  fromTransaction.getCityList,
);
export const getTransactionSelectedProvince = createSelector(
  getTransactionState,
  fromTransaction.getSelectedProv,
);
export const getTransactionInvoiceList = createSelector(
  getTransactionState,
  fromTransaction.getInvoiceList,
);
export const getTransactionFilterChanged = createSelector(
  getTransactionState,
  fromTransaction.getFilterChanged,
);
export const getTransactionAdditionalAlarmSearchFilter = createSelector(
  getTransactionState,
  fromTransaction.getAdditionalAlarmSearchFilter,
);
