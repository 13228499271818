<h1 class="dialog-title" mat-dialog-title>{{ data.title | translate }}</h1>

<div mat-dialog-content>
  <h2 *ngIf="data.subTitle">{{ data.subTitle | translate }}</h2>
  <p>{{ data.message | translate }}</p>
</div>

<div mat-dialog-actions class="confirm-actions">
  <button mat-raised-button color="accent" (click)="closeDialog()" class="rspndr-button uppercase">
    <span translate>Close</span>
  </button>
</div>
