import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as actions from './stats.component.actions';
import { RspndrStats } from '../@core/@models/rspndr';

export interface StatsState {
  stats: RspndrStats;
}

const initialState: StatsState = {
  stats: undefined,
};

export function statsComponentReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: StatsState = initialState,
  action: actions.StatsActions,
): StatsState {
  if (!!action) {
    switch (action.type) {
      case actions.STATS_ACTION_TYPES.STATS_UPDATE_SUCCESS:
        return Object.assign({}, state, {
          stats: action.payload,
        });

      default:
        return Object.assign({}, state);
    }
  } else {
    // eslint-disable-next-line no-console
    console.log(`WARNING: action is NULL!!! ${JSON.stringify(action)}`);
  }

  return Object.assign({}, state);
}

const statsComponentSelector = createFeatureSelector<StatsState>('rspndr-stats-component');
export const getStats = createSelector(statsComponentSelector, (state: StatsState) => state.stats);
