<div class="snackbar-container">
  <p>
    <span>An error occurred for this action: </span>
    <span>{{ data.action }}</span>
  </p>
  <p [ngSwitch]="data.error?.status" class="server-error-message">
    <span *ngSwitchCase="403" translate>Your account is not authorized for this action.</span>
    <span *ngSwitchCase="500" translate>An internal server error occurred.</span>
    <span *ngSwitchDefault
      >{{ data.error?.error?.status }} - "{{ data.error?.error?.message }}"</span
    >
  </p>
</div>

<div class="snackbar-actions">
  <button mat-button (click)="close()" translate>Dismiss</button>
</div>
