import { createReducer, on } from '@ngrx/store';
import * as appActions from './app.actions';
import { RspndrAlarm, RspndrOrganisation } from './@core/@models/rspndr';
import { RspndrBrowserPreferences } from './tabs/browser-preferences/browser-preferences.component';

export interface AppState {
  browserPreferences: RspndrBrowserPreferences;
  error: Error;
  organisationsList: RspndrOrganisation[];
  alarmDetail: RspndrAlarm;
  alarmDetailLoading: boolean;
  updateAddressLoading: boolean;
  assignGuardLoading: boolean;
  subscriberUploadLoading: boolean;
  subscriberUploadErrorMessage: string;
}

export const initialState: AppState = {
  browserPreferences: {
    inactiveBrowserPollingPause: true,
    defaultAlarmNotifications: {
      info: true,
      warn: true,
      error: true,
    },
  },
  error: undefined,
  organisationsList: [],
  alarmDetail: undefined,
  alarmDetailLoading: false,
  updateAddressLoading: false,
  assignGuardLoading: false,
  subscriberUploadLoading: false,
  subscriberUploadErrorMessage: undefined,
};

export const getBrowserPreferences = (state: AppState) => state.browserPreferences;
export const getError = (state: AppState) => state.error;
export const getOrganisationsList = (state: AppState) => state.organisationsList;
export const getOrganisationsListEnabledOnly = (state: AppState) =>
  state.organisationsList.filter((org) => org.enabled !== false);
export const getAlarmDetail = (state: AppState) => state.alarmDetail;
export const getAlarmDetailLoading = (state: AppState) => state.alarmDetailLoading;
export const getUpdateAddressLoading = (state: AppState) => state.updateAddressLoading;
export const getAssignGuardLoading = (state: AppState) => state.assignGuardLoading;

export const getSubscriberUploadLoading = (state: AppState) => state.subscriberUploadLoading;

export const getSubscriberUploadErrorMessage = (state: AppState) =>
  state.subscriberUploadErrorMessage;

export const appReducer = createReducer(
  initialState,
  on(
    appActions.loadBrowserPreferencesSuccessAction,
    (state, { browserPreferences }): AppState => ({
      ...state,
      browserPreferences,
    }),
  ),
  on(
    appActions.loadOrganisationsListSuccessAction,
    (state, { organisationsList }): AppState => ({
      ...state,
      organisationsList: organisationsList.sort((a, b) =>
        a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase(), 'en'),
      ),
    }),
  ),
  on(appActions.alarmDetailAction, (state): AppState => ({ ...state, alarmDetailLoading: true })),
  on(
    appActions.alarmDetailSuccessAction,
    (state, { alarmDetail }): AppState => ({
      ...state,
      alarmDetailLoading: false,
      alarmDetail,
    }),
  ),
  on(
    appActions.alarmDetailFailAction,
    (state): AppState => ({ ...state, alarmDetailLoading: false }),
  ),
  on(
    appActions.alarmDetailClearAction,
    (state): AppState => ({ ...state, alarmDetail: undefined }),
  ),
  on(
    appActions.alarmUpdateAddressAction,
    (state): AppState => ({ ...state, updateAddressLoading: true }),
  ),
  on(
    appActions.alarmUpdateAddressSuccessAction,
    (state): AppState => ({
      ...state,
      updateAddressLoading: false,
    }),
  ),
  on(
    appActions.alarmUpdateAddressFailAction,
    (state): AppState => ({
      ...state,
      updateAddressLoading: false,
    }),
  ),
  on(
    appActions.alarmAssignGuardAction,
    (state): AppState => ({ ...state, assignGuardLoading: true }),
  ),
  on(
    appActions.alarmAssignGuardSuccessAction,
    (state): AppState => ({
      ...state,
      assignGuardLoading: false,
    }),
  ),
  on(
    appActions.alarmAssignGuardFailAction,
    (state): AppState => ({ ...state, assignGuardLoading: false }),
  ),
  on(
    appActions.alarmGuardReassignAction,
    (state): AppState => ({ ...state, assignGuardLoading: true }),
  ),
  on(
    appActions.alarmGuardReassignSuccessAction,
    (state): AppState => ({ ...state, assignGuardLoading: false }),
  ),
  on(
    appActions.alarmAssignGuardFailAction,
    (state): AppState => ({ ...state, assignGuardLoading: false }),
  ),
  on(
    appActions.subscriberUploadAction,
    (state): AppState => ({
      ...state,
      subscriberUploadLoading: true,
      subscriberUploadErrorMessage: undefined,
    }),
  ),
  on(
    appActions.subscriberUploadSuccessAction,
    (state): AppState => ({ ...state, subscriberUploadLoading: false }),
  ),
  on(
    appActions.subscriberUploadFailAction,
    (state, { errorMessage }): AppState => ({
      ...state,
      subscriberUploadLoading: false,
      subscriberUploadErrorMessage: errorMessage,
    }),
  ),
  on(
    appActions.subscriberUploadClearErrorMessageAction,
    (state): AppState => ({ ...state, subscriberUploadErrorMessage: undefined }),
  ),
);
