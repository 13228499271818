import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RspndrConfig } from '../@models/common';
import { RspndrInvoice } from '../@models/rspndr';

@Injectable()
export class RspndrInvoiceApi {
  constructor(private config: RspndrConfig, private http: HttpClient) {}

  getInvoice(invoiceNumber: string): Observable<RspndrInvoice> {
    return this.http.get<RspndrInvoice>(`${this.config.invoiceUrl}/api/invoices/${invoiceNumber}`);
  }

  getInvoicePdf(invoiceNumber: string): Observable<Blob> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(
      `${this.config.invoiceUrl}/api/invoices/${invoiceNumber}.pdf`,
      httpOptions,
    );
  }

  getInvoiceCsv(invoiceNumber: string): Observable<Blob> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'text/csv',
      }),
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(
      `${this.config.invoiceUrl}/api/invoices/${invoiceNumber}.csv`,
      httpOptions,
    );
  }

  triggerReceivableInvoice(): Observable<any> {
    return this.http.get(`${this.config.invoiceUrl}/api/invoices/trigger/receivable/invoice`);
  }

  triggerPayableInvoice(): Observable<any> {
    return this.http.get(`${this.config.invoiceUrl}/api/invoices/trigger/payable/invoice`);
  }
}
