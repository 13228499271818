import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import moment from 'moment';
import { RspndrConfig } from '../@models/common';
import {
  RspndrStats,
  RspndrAlarm,
  RspndrStatsRequest,
  RspndrPage,
  RspndrResponse,
} from '../@models/rspndr';
import { RSPNDR_CITY_LIST_ITEM, RSPNDR_INVOICE_LIST_ITEM } from '../@models/constants';

@Injectable()
export class RspndrStatApi {
  constructor(private config: RspndrConfig, private http: HttpClient) {}

  stats(localDate?: string): Observable<RspndrStats> {
    const params: HttpParams = new HttpParams().set(
      'localDate',
      !!localDate ? localDate : moment().format('YYYY-MM-DD'),
    );
    return this.http.get<RspndrStats>(`${this.config.baseUrl}/api/stats`, {
      params,
    });
  }

  searchAlarm(
    page: RspndrPage<RspndrStatsRequest, RspndrAlarm>,
  ): Observable<RspndrResponse<RspndrPage<RspndrStatsRequest, RspndrAlarm>>> {
    return this.http.post<RspndrResponse<RspndrPage<RspndrStatsRequest, RspndrAlarm>>>(
      `${this.config.baseUrl}/api/stats/search/alarm`,
      page,
    );
  }

  transactions(request: RspndrStatsRequest): Observable<RspndrStats> {
    return this.http.post<RspndrStats>(`${this.config.baseUrl}/api/stats/transactions`, request);
  }

  countryProvCity(tenantId?: string): Observable<RSPNDR_CITY_LIST_ITEM[]> {
    const request: RspndrStatsRequest = { tenantId };
    return this.http.post<RSPNDR_CITY_LIST_ITEM[]>(
      `${this.config.baseUrl}/api/stats/countryprovcity`,
      request,
    );
  }

  invoiceList(tenantId?: string): Observable<RSPNDR_INVOICE_LIST_ITEM[]> {
    const request: RspndrStatsRequest = { tenantId };
    return this.http.post<RSPNDR_INVOICE_LIST_ITEM[]>(
      `${this.config.baseUrl}/api/stats/invoicelist`,
      request,
    );
  }

  dispatchTime(alarm: RspndrAlarm): number {
    if (!!alarm && !!alarm.triggeredAt && !!alarm.takenAt) {
      return alarm.takenAt - alarm.triggeredAt;
    }
    return 0;
  }

  responseTime(alarm: RspndrAlarm): number {
    if (!!alarm && !!alarm.triggeredAt && !!alarm.takenAt) {
      return alarm.arrivedAt - alarm.takenAt;
    }
    return 0;
  }
}
