import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';

import { MatCardModule } from '@angular/material/card';
import { DirectivesModule } from '../@core/@directives/directives.module';

import { StatsComponentEffects } from './stats.component.effects';
import { statsComponentReducer } from './stats.component.reducer';
import { RspndrStatsComponent } from './stats.component';

@NgModule({
  declarations: [RspndrStatsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature('rspndr-stats-component', statsComponentReducer),
    EffectsModule.forFeature([StatsComponentEffects]),
    TranslateModule,
    DirectivesModule,
    MatCardModule,
  ],
  exports: [RspndrStatsComponent],
})
export class RspndrStatsComponentModule {}
