import { createAction, props } from '@ngrx/store';
import {
  RspndrPresetDateRangeType,
  RSPNDR_CITY_LIST_ITEM,
  RSPNDR_INVOICE_LIST_ITEM,
} from '../@core/@models/constants';
import {
  RspndrResponse,
  RspndrPage,
  RspndrStatsRequest,
  RspndrAlarm,
  RspndrStats,
} from '../@core/@models/rspndr';
import { DateRangeModel } from '../home/home.actions';

export const TRANSACTION_ACTION_TYPES = {
  TRANSACTION_LOAD_CITY_LIST_SUCCESS: '[Transaction] Load City List Success',
  TRANSACTION_LOAD_CITY_LIST_FAIL: '[Transaction] Load City List Fail',
  TRANSACTION_LOAD_INVOICE_LIST_SUCCESS: '[Transaction] Load Invoice List Success',
  TRANSACTION_LOAD_INVOICE_LIST_FAIL: '[Transaction] Load Invoice List Fail',
  TRANSACTION_SEARCH: '[Transaction] Search',
  TRANSACTION_SEARCH_SUCCESS: '[Transaction] Search Success',
  TRANSACTION_SEARCH_FAIL: '[Transaction] Search Fail',
  TRANSACTION_STATS: '[Transaction] Stats',
  TRANSACTION_STATS_SUCCESS: '[Transaction] Stats Success',
  TRANSACTION_STATS_FAIL: '[Transaction] Stats Fail',
  TRANSACTION_SET_PAGE: '[Transaction] Set Page',
  TRANSACTION_SET_PAGE_SIZE: '[Transaction] Set Page Size',
  TRANSACTION_SELECT: '[Transaction] Select',
  TRANSACTION_INVOICE_PDF: '[Transaction] Invoice PDF',
  TRANSACTION_INVOICE_CSV: '[Transaction] Invoice CSV',

  // FILTER COMPONENT ACTIONS
  TRANSACTION_FILTER_CLEAR: '[Transaction] Filter Clear',
  TRANSACTION_FILTER_SET_TENANT: '[Transaction] Filter Set Tenant',
  TRANSACTION_FILTER_SELECT_INVOICE: '[Transaction] Filter Select Invoice',
  TRANSACTION_FILTER_SET_DATE_RANGE: '[Transaction] Filter Set Date Range',
  TRANSACTION_FILTER_SELECT_PROV: '[Transaction] Filter Select Province',
  TRANSACTION_FILTER_SELECT_CITY: '[Transaction] Filter Select City',
  TRANSACTION_FILTER_SET_FILTER_TEXT: '[Transaction] Filter Set Filter Text',
  TRANSACTION_FILTER_SET_INCIDENT_NUMBER: '[Transaction] Filter Set Incident Number',
  TRANSACTION_FILTER_SET_ADDITIONAL_FILTER: '[Transaction] Filter Set Additional',
  TRANSACTION_DEBUG_REC_INVOICE: '[Transaction] Filter DEBUG Rec Invoice',
  TRANSACTION_DEBUG_PAY_INVOICE: '[Transaction] Filter DEBUG Pay Invoice',
  TRANSACTION_NOOP: '[Transaction] No Op Action',
};

export const transactionLoadCityListSuccessAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_LOAD_CITY_LIST_SUCCESS,
  props<{ cityList: RSPNDR_CITY_LIST_ITEM[] }>(),
);
export const transactionLoadCityListFailAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_LOAD_CITY_LIST_FAIL,
  props<{ error: Error }>(),
);
export const transactionLoadInvoiceListSuccessAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_LOAD_INVOICE_LIST_SUCCESS,
  props<{ invoiceList: RSPNDR_INVOICE_LIST_ITEM[] }>(),
);
export const transactionLoadInvoiceListFailAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_LOAD_INVOICE_LIST_FAIL,
  props<{ error: Error }>(),
);
export const transactionSearchAction = createAction(TRANSACTION_ACTION_TYPES.TRANSACTION_SEARCH);
export const transactionSearchSuccessAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_SEARCH_SUCCESS,
  props<{ response: RspndrResponse<RspndrPage<RspndrStatsRequest, RspndrAlarm>> }>(),
);
export const transactionSearchFailAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_SEARCH_FAIL,
  props<{ error: Error }>(),
);
export const transactionStatsAction = createAction(TRANSACTION_ACTION_TYPES.TRANSACTION_STATS);
export const transactionStatsSuccessAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_STATS_SUCCESS,
  props<{ stats: RspndrStats }>(),
);
export const transactionStatsFailAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_STATS_FAIL,
  props<{ error: Error }>(),
);
export const transactionSelectAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_SELECT,
  props<{ selectedAlarmId: string }>(),
);
export const transactionSetPageAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_SET_PAGE,
  props<{ pageNumber: number }>(),
);
export const transactionSetPageSizeAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_SET_PAGE_SIZE,
  props<{ pageSize: number; pageNumber: number }>(),
);
export const transactionInvoicePdfAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_INVOICE_PDF,
);
export const transactionInvoiceCsvAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_INVOICE_CSV,
);
export const transactionFilterClearAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_FILTER_CLEAR,
  props<{ translationText: any }>(),
);
export const transactionFilterSetTenantAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_FILTER_SET_TENANT,
  props<{ tenantId: string }>(),
);
export const transactionFilterSelectInvoiceAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_FILTER_SELECT_INVOICE,
  props<{ value: string; translationText: any }>(),
);
export const transactionFilterSetDateRangeAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_FILTER_SET_DATE_RANGE,
  props<{
    presetName?: RspndrPresetDateRangeType;
    dateRange?: DateRangeModel;
    translationText: any;
  }>(),
);
export const transactionFilterSelectProvinceAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_FILTER_SELECT_PROV,
  props<{ selectedProvince: string }>(),
);
export const transactionFilterSelectCityAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_FILTER_SELECT_CITY,
  props<{ city: string }>(),
);
export const transactionFilterSetFilterTextAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_FILTER_SET_FILTER_TEXT,
  props<{ filterText: string }>(),
);
export const transactionFilterSetFilterIncidentNumberAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_FILTER_SET_INCIDENT_NUMBER,
  props<{ value: string; translationText: any }>(),
);
export const transactionFilterSetAdditionalFilterAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_FILTER_SET_ADDITIONAL_FILTER,
  props<{ additionalAlarmSearchFilter: string }>(),
);
export const transactionDebugRecInvoiceAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_DEBUG_REC_INVOICE,
);
export const transactionDebugPayInvoiceAction = createAction(
  TRANSACTION_ACTION_TYPES.TRANSACTION_DEBUG_PAY_INVOICE,
);
export const transactionNoOpAction = createAction(TRANSACTION_ACTION_TYPES.TRANSACTION_NOOP);
