import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RspndrConfig } from '../@models/common';

export interface ActuatorInfo {
  build: {
    artifact: string;
    name: string;
    version: string;
  };
  git: {
    branch: string;
    commit: {
      time: number;
      message: {
        full: string;
        short: string;
      };
      id: {
        describe: string;
        abbrev: string;
        full: string;
      };
    };
  };
}

export interface ActuatorHealth {
  status: string; // 'UP' OR 'DOWN'
}

@Injectable()
export class RspndrActuatorApi {
  constructor(private config: RspndrConfig, private http: HttpClient) {}

  health(): Observable<ActuatorHealth> {
    return this.http.get<ActuatorHealth>(`${this.config.baseUrl}/health`);
  }

  info(): Observable<ActuatorInfo> {
    return this.http.get<ActuatorInfo>(`${this.config.baseUrl}/info`);
  }
}
