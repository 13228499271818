import { Injectable } from '@angular/core';
import { RspndrAlarm, RspndrAlarmStats } from '../@models/rspndr';

@Injectable()
export class RspndrStatsService {
  calcAlarmStats(alarm: RspndrAlarm): RspndrAlarmStats {
    const alarmStats: RspndrAlarmStats = {
      mcDispatchTime:
        !!alarm && !!alarm.triggeredAt && !!alarm.createdAt && alarm.triggeredAt < alarm.createdAt
          ? alarm.createdAt - alarm.triggeredAt
          : 60000,
      dispatchTime:
        !!alarm && !!alarm.createdAt && !!alarm.takenAt && alarm.createdAt < alarm.takenAt
          ? alarm.takenAt - alarm.createdAt
          : 0,
      responseTime:
        !!alarm && !!alarm.takenAt && !!alarm.arrivedAt && alarm.takenAt < alarm.arrivedAt
          ? alarm.arrivedAt - alarm.takenAt
          : 0,
      reportTime:
        !!alarm && !!alarm.arrivedAt && !!alarm.finishedAt && alarm.arrivedAt < alarm.finishedAt
          ? alarm.finishedAt - alarm.arrivedAt
          : 0,
      cancelledElapsed: 0,
      timezone: !!alarm.localTriggeredDt ? alarm.localTriggeredDt.timezone : '',
      triggeredTime: !!alarm.localTriggeredDt ? alarm.localTriggeredDt.timeShort : '',
      triggeredDate: !!alarm.localTriggeredDt ? alarm.localTriggeredDt.date : '',
      createdTime: !!alarm.localCreatedDt ? alarm.localCreatedDt.timeShort : '',
      createdDate: !!alarm.localCreatedDt ? alarm.localCreatedDt.date : '',
      assignedTime: !!alarm.localTakenDt ? alarm.localTakenDt.timeShort : '',
      assignedDate: !!alarm.localTakenDt ? alarm.localTakenDt.date : '',
      arrivedTime: !!alarm.localArrivedDt ? alarm.localArrivedDt.timeShort : '',
      arrivedDate: !!alarm.localArrivedDt ? alarm.localArrivedDt.date : '',
      finishedTime: !!alarm.localFinishedDt ? alarm.localFinishedDt.timeShort : '',
      finishedDate: !!alarm.localFinishedDt ? alarm.localFinishedDt.date : '',
      cancelledTime: !!alarm.localCancelledDt ? alarm.localCancelledDt.timeShort : '',
      cancelledDate: !!alarm.localCancelledDt ? alarm.localCancelledDt.date : '',
      showCancelledReview: false,
    };
    if (!!alarm && !!alarm.takenAt && !!alarm.cancelledAt && alarm.takenAt < alarm.cancelledAt) {
      alarmStats.cancelledElapsed = alarm.cancelledAt - alarm.takenAt;
    } else if (
      !!alarm &&
      !alarm.takenAt &&
      !!alarm.cancelledAt &&
      !!alarm.createdAt &&
      alarm.createdAt < alarm.cancelledAt
    ) {
      alarmStats.cancelledElapsed = alarm.cancelledAt - alarm.createdAt;
    }
    alarmStats.createdDate =
      alarmStats.triggeredDate === alarmStats.createdDate ? '' : alarmStats.createdDate;
    alarmStats.assignedDate =
      alarmStats.createdDate === alarmStats.assignedDate ? '' : alarmStats.assignedDate;
    alarmStats.arrivedDate =
      alarmStats.assignedDate === alarmStats.arrivedDate ? '' : alarmStats.arrivedDate;
    alarmStats.finishedDate =
      alarmStats.arrivedDate === alarmStats.finishedDate ? '' : alarmStats.finishedDate;
    alarmStats.cancelledDate =
      alarmStats.assignedDate === alarmStats.cancelledDate ? '' : alarmStats.cancelledDate;
    alarmStats.showCancelledReview =
      alarm.state === 'CANCELLED' &&
      !!alarm.reviewRequired &&
      !!alarm.assignedTo &&
      !!alarm.takenAt &&
      alarmStats.cancelledElapsed >= 10 * 60 * 1000; // more than 10 minutes
    return alarmStats;
  }

  alarmsWithStats(alarms: RspndrAlarm[]): RspndrAlarm[] {
    return alarms.map((alarm) => {
      const withStats = {
        _stats: this.calcAlarmStats(alarm),
        ...alarm,
      };
      return withStats;
    });
  }
}
