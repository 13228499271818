import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type ConfirmationErrorData = {
  title: string;
  subTitle?: string;
  message: string;
};

/**
 * Dialog Component to display a simple confirmation or error message
 */
@Component({
  selector: 'app-confirmation-error-dialog',
  templateUrl: './confirmation-error-dialog.component.html',
  styleUrls: ['./confirmation-error-dialog.component.scss'],
})
export class ConfirmationErrorDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationErrorData,
    public dialogRef: MatDialogRef<ConfirmationErrorDialogComponent>,
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
