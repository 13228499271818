import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RspndrConfig } from '../@models/common';
import { RspndrCharge } from '../@models/rspndr';

@Injectable()
export class RspndrChargeApi {
  constructor(private config: RspndrConfig, private http: HttpClient) {}

  saveAlarmCharge(id: string, charge: RspndrCharge): Observable<RspndrCharge> {
    return this.http.post<RspndrCharge>(`${this.config.invoiceUrl}/api/charges`, charge);
  }

  getAlarmCharges(id: string): Observable<RspndrCharge[]> {
    return this.http.get<RspndrCharge[]>(`${this.config.invoiceUrl}/api/charges/alarms/${id}`);
  }
}
