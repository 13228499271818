import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

interface PaginatorIntlDict {
  itemsPerPageLabel: string;
  nextPageLabel: string;
  previousPageLabel: string;
  firstPageLabel: string;
  lastPageLabel: string;
}

export class PaginatorI18n {
  constructor(private readonly translate: TranslateService) {}

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();
    this.translate.stream('MAT_PAGINATOR_INTL').subscribe((res: PaginatorIntlDict) => {
      Object.assign(paginatorIntl, res);
      paginatorIntl.changes.next();
    });

    // translate the "of" in the range label
    const originalGetRangeLabel = paginatorIntl.getRangeLabel;
    paginatorIntl.getRangeLabel = (page: number, size: number, len: number) =>
      originalGetRangeLabel(page, size, len).replace('of', this.translate.instant('of'));
    return paginatorIntl;
  }
}
