import * as umActions from './usermanagement.actions';
import { RspndrGuard, UserManagementFilterCriteria } from '../@core/@models/rspndr';
import { Page } from '../@core/@api/portal';

export interface UserManagementState {
  filterCriteria: UserManagementFilterCriteria;
  users: Page<RspndrGuard>;
  saveLoading: boolean;
  selected: RspndrGuard[];
  loading: boolean;
  error: Error;
  pwdResetTitle: string;
}

export const initialState: UserManagementState = {
  filterCriteria: {
    searchText: '',
    status: 'ALL',
    userType: 'ALL',
    pageNumber: 0,
    pageSize: 10,
    sortProperty: 'status',
    sortDirection: 'ASC',
  },
  users: <Page<RspndrGuard>>{
    content: [],
  },
  saveLoading: false,
  selected: [],
  loading: false,
  error: undefined,
  pwdResetTitle: '',
};

export function userManagementReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: UserManagementState = initialState,
  action: umActions.UserManagementActions,
): UserManagementState {
  if (!!action) {
    switch (action.type) {
      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_SEARCH_TEXT: {
        return {
          ...state,
          filterCriteria: {
            ...state.filterCriteria,
            searchText: action.payload,
          },
          loading: true,
        };
      }

      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_USER_STATE: {
        return {
          ...state,
          filterCriteria: {
            ...state.filterCriteria,
            status: action.payload,
          },
          loading: true,
        };
      }

      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_USER_TYPE: {
        return {
          ...state,
          filterCriteria: {
            ...state.filterCriteria,
            userType: action.payload,
          },
          loading: true,
        };
      }

      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_PAGING: {
        return {
          ...state,
          filterCriteria: {
            ...state.filterCriteria,
            pageNumber: action.payload.pageNumber,
            pageSize: action.payload.pageSize,
          },
          loading: true,
        };
      }

      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_SORTING: {
        return {
          ...state,
          filterCriteria: {
            ...state.filterCriteria,
            sortProperty: action.payload.name,
            sortDirection: action.payload.direction,
          },
          loading: true,
        };
      }

      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_NEW_USER_PASSWORD: {
        return {
          ...state,
          pwdResetTitle: 'User Created Successfully',
        };
      }

      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_CREATE: {
        return {
          ...state,
          saveLoading: true,
        };
      }

      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_CREATE_SUCCESS: {
        return {
          ...state,
          saveLoading: false,
        };
      }

      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_CREATE_FAIL: {
        return {
          ...state,
          saveLoading: false,
          error: action.payload,
        };
      }

      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_UPDATE: {
        return {
          ...state,
          loading: true,
        };
      }

      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_UPDATE_SUCCESS: {
        return Object.assign({}, state, {
          editorMode: 'HIDDEN',
          loading: false,
          detailLoading: false,
        });
      }

      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_UPDATE_FAIL: {
        return Object.assign({}, state, {
          editorMode: 'HIDDEN',
          loading: false,
          detailLoading: false,
          error: action.payload,
        });
      }

      // list
      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SEARCH: {
        return {
          ...state,
          loading: true,
        };
      }
      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SEARCH_SUCCESS: {
        return {
          ...state,
          users: action.payload,
          loading: false,
        };
      }
      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SEARCH_FAIL: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }

      // delete
      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_DELETE: {
        return {
          ...state,
          loading: true,
        };
      }
      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_DELETE_SUCCESS: {
        return {
          ...state,
          loading: false,
        };
      }
      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_DELETE_FAIL: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }

      // enable
      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_ENABLE: {
        return {
          ...state,
          loading: true,
        };
      }
      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_ENABLE_SUCCESS: {
        return {
          ...state,
          loading: false,
        };
      }
      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_ENABLE_FAIL: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }

      // select
      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SELECT: {
        return {
          ...state,
          selected: (action as umActions.UserMgtSelectAction).payload,
        };
      }

      // Password Reset
      case umActions.USER_MANAGEMENT_ACTION_TYPES.USER_MGT_RESET_PASSWORD: {
        return {
          ...state,
          pwdResetTitle: 'Password Reset Success',
        };
      }

      default:
        return state;
    }
  }

  return state;
}

export const getUserManagementUsersPage = (state: UserManagementState) => state.users;
export const getUserManagementFilterCriteria = (state: UserManagementState) => state.filterCriteria;
export const getUserManagementSelected = (state: UserManagementState) => state.selected;
export const getUserManagementLoading = (state: UserManagementState) => state.loading;
export const getUserManagementError = (state: UserManagementState) => state.error;
export const getUserManagementSaveLoading = (state: UserManagementState) => state.saveLoading;
