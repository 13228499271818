import { Action } from '@ngrx/store';

import { RspndrUtil } from '../@core/util';
import { RspndrStats } from '../@core/@models/rspndr';

export const STATS_ACTION_TYPES = {
  STATS_UPDATE: RspndrUtil.type('[Stats Component] Stats Update'),
  STATS_UPDATE_SUCCESS: RspndrUtil.type('[Stats Component] Stats Update Success'),
  NOOP: RspndrUtil.type('[Stats Component] No Op'),
};

export class StatsUpdateAction implements Action {
  readonly type = STATS_ACTION_TYPES.STATS_UPDATE;
}

export class StatsUpdateSuccessAction implements Action {
  readonly type = STATS_ACTION_TYPES.STATS_UPDATE_SUCCESS;
  constructor(public payload: RspndrStats) {}
}

export class StatsNoOpAction implements Action {
  readonly type = STATS_ACTION_TYPES.NOOP;
}

export type StatsActions = StatsUpdateAction | StatsUpdateSuccessAction | StatsNoOpAction;
