import { Action } from '@ngrx/store';
import {
  RspndrCredentials,
  RspndrGuard,
  RspndrGuardFrontendDto,
  RspndrPage,
  RspndrUserFilterCriteria,
  RspndrUserFilterState,
  RspndrUserFilterType,
} from '../@core/@models/rspndr';

import { RspndrUtil } from '../@core/util';
import { Page } from '../@core/@api/portal';

export const USER_MANAGEMENT_ACTION_TYPES = {
  USER_MGT_REFRESH: RspndrUtil.type('[User Management] Refresh'),
  USER_MGT_SET_SEARCH_CRITERIA: RspndrUtil.type('[User Management] Set Search Criteria'),
  USER_MGT_SET_SEARCH_TEXT: RspndrUtil.type('[User Management] Set Search Text'),
  USER_MGT_SET_USER_STATE: RspndrUtil.type('[User Management] Set User State'),
  USER_MGT_SET_USER_TYPE: RspndrUtil.type('[User Management] Set User Type'),
  USER_MGT_SET_PAGING: RspndrUtil.type('[User Management] Set Paging'),
  USER_MGT_SET_SORTING: RspndrUtil.type('[User Management] Set Sorting'),
  USER_MGT_CREATE: RspndrUtil.type('[User Management] Create'),
  USER_MGT_CREATE_SUCCESS: RspndrUtil.type('[User Management] Create Success'),
  USER_MGT_CREATE_FAIL: RspndrUtil.type('[User Management] Create Fail'),
  USER_MGT_UPDATE: RspndrUtil.type('[User Management] Update'),
  USER_MGT_UPDATE_SUCCESS: RspndrUtil.type('[User Management] Update Success'),
  USER_MGT_UPDATE_FAIL: RspndrUtil.type('[User Management] Update Fail'),
  USER_MGT_SEARCH: RspndrUtil.type('[User Management] Search'),
  USER_MGT_SEARCH_SUCCESS: RspndrUtil.type('[User Management] Search Success'),
  USER_MGT_SEARCH_FAIL: RspndrUtil.type('[User Management] Search Fail'),
  USER_MGT_DELETE: RspndrUtil.type('[User Management] Delete'),
  USER_MGT_DELETE_SUCCESS: RspndrUtil.type('[User Management] Delete Success'),
  USER_MGT_DELETE_FAIL: RspndrUtil.type('[User Management] Delete Fail'),
  USER_MGT_ENABLE: RspndrUtil.type('[User Management] Enable'),
  USER_MGT_ENABLE_SUCCESS: RspndrUtil.type('[User Management] Enable Success'),
  USER_MGT_ENABLE_FAIL: RspndrUtil.type('[User Management] Enable Fail'),
  USER_MGT_LICENSE: RspndrUtil.type('[User Management] License'),
  USER_MGT_LICENSE_SUCCESS: RspndrUtil.type('[User Management] License Success'),
  USER_MGT_LICENSE_FAIL: RspndrUtil.type('[User Management] License Fail'),
  USER_MGT_SELECT: RspndrUtil.type('[User Management] Select'),
  USER_MGT_LOGOUT: RspndrUtil.type('[User Management] Logout'),
  USER_MGT_GUARD_CACHE_CLEAR: RspndrUtil.type('[User Management] Guard Cache Clear'),
  USER_MGT_GUARD_CACHE_CLEAR_SUCCESS: RspndrUtil.type(
    '[User Management] Guard Cache Clear Success',
  ),
  USER_MGT_GUARD_CACHE_CLEAR_FAIL: RspndrUtil.type('[User Management] Guard Cache Clear Failed'),
  USER_MGT_RESET_PASSWORD: RspndrUtil.type('[User Management] Reset Password'),
  USER_MGT_NEW_USER_PASSWORD: RspndrUtil.type('[User Management] New User Password'),
  USER_MGT_RESET_PASSWORD_SUCCESS: RspndrUtil.type('[User Management] Reset Password Success'),
  USER_MGT_RESET_PASSWORD_FAIL: RspndrUtil.type('[User Management] Reset Password Fail'),
  USER_MGT_NOOP: RspndrUtil.type('[User Management] No Op'),
};

export class UserMgtRefreshAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_REFRESH;
  constructor(public payload?: any) {}
}

export class UserMgtSetSearchTextAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_SEARCH_TEXT;
  constructor(public payload: string) {}
}

export class UserMgtSetUserStateAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_USER_STATE;
  constructor(public payload: RspndrUserFilterState) {}
}

export class UserMgtSetUserTypeAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_USER_TYPE;
  constructor(public payload: RspndrUserFilterType) {}
}

export class UserMgtSetPagingAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_PAGING;
  constructor(public payload: RspndrPage<RspndrUserFilterCriteria, RspndrGuard>) {}
}

export class UserMgtSetSortingAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SET_SORTING;
  constructor(public payload: { name: string; direction: 'ASC' | 'DESC' }) {}
}

export class UserMgtCreateAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_CREATE;
  constructor(public payload: { user: RspndrGuardFrontendDto; tenantId?: string }) {}
}

export class UserMgtCreateSuccessAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_CREATE_SUCCESS;
  constructor(public payload: RspndrGuard) {}
}

export class UserMgtCreateFailAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_CREATE_FAIL;
  constructor(public payload: Error) {}
}

export class UserMgtUpdateAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_UPDATE;
  constructor(public payload: RspndrGuardFrontendDto) {}
}

export class UserMgtUpdateSuccessAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_UPDATE_SUCCESS;
  constructor(public payload: RspndrGuard) {}
}

export class UserMgtUpdateFailAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_UPDATE_FAIL;
  constructor(public payload: Error) {}
}

export class UserMgtSearchAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SEARCH;
  constructor(public payload?: any) {}
}

export class UserMgtSearchSuccessAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SEARCH_SUCCESS;
  constructor(public payload: Page<RspndrGuard>) {}
}

export class UserMgtSearchFailAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SEARCH_FAIL;
  constructor(public payload: Error) {}
}

export class UserMgtDeleteAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_DELETE;
  constructor(public payload: string) {}
}

export class UserMgtDeleteSuccessAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_DELETE_SUCCESS;
  constructor(public payload: string) {}
}

export class UserMgtDeleteFailAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_DELETE_FAIL;
  constructor(public payload: Error) {}
}

export class UserMgtEnableAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_ENABLE;
  constructor(public payload: { userId: string }) {}
}

export class UserMgtEnableSuccessAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_ENABLE_SUCCESS;
  constructor(public payload: { userId: string }) {}
}

export class UserMgtEnableFailAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_ENABLE_FAIL;
  constructor(public payload: Error) {}
}

export class UserMgtSelectAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_SELECT;
  constructor(public payload: RspndrGuard[]) {}
}

export class UserMgtLogoutAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_LOGOUT;
  constructor(public payload: { userId: string }) {}
}

export class UserMgtGuardCacheClearAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_GUARD_CACHE_CLEAR;
  constructor(public payload?: any) {}
}

export class UserMgtGuardCacheClearSuccessAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_GUARD_CACHE_CLEAR_SUCCESS;
  constructor(public payload?: any) {}
}

export class UserMgtGuardCacheClearFailAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_GUARD_CACHE_CLEAR_FAIL;
  constructor(public payload?: any) {}
}

export class UserMgtResetPasswordAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_RESET_PASSWORD;
  constructor(public payload: { userId: string }) {}
}

export class UserMgtNewUserPasswordAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_NEW_USER_PASSWORD;
  constructor(public payload: RspndrCredentials) {}
}

export class UserMgtResetPasswordSuccessAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_RESET_PASSWORD_SUCCESS;
  constructor(public payload?: any) {}
}

export class UserMgtResetPasswordFailAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_RESET_PASSWORD_FAIL;
  constructor(public payload: any) {}
}

export class UserMgtNoopAction implements Action {
  readonly type = USER_MANAGEMENT_ACTION_TYPES.USER_MGT_NOOP;
  constructor(public payload?: any) {}
}

export type UserManagementActions =
  | UserMgtRefreshAction
  | UserMgtSetSearchTextAction
  | UserMgtSetUserStateAction
  | UserMgtSetUserTypeAction
  | UserMgtSetPagingAction
  | UserMgtSetSortingAction
  | UserMgtCreateAction
  | UserMgtCreateSuccessAction
  | UserMgtCreateFailAction
  | UserMgtUpdateAction
  | UserMgtUpdateSuccessAction
  | UserMgtUpdateFailAction
  | UserMgtSearchAction
  | UserMgtSearchSuccessAction
  | UserMgtSearchFailAction
  | UserMgtDeleteAction
  | UserMgtDeleteSuccessAction
  | UserMgtDeleteFailAction
  | UserMgtEnableAction
  | UserMgtEnableSuccessAction
  | UserMgtEnableFailAction
  | UserMgtSelectAction
  | UserMgtLogoutAction
  | UserMgtGuardCacheClearAction
  | UserMgtGuardCacheClearSuccessAction
  | UserMgtGuardCacheClearFailAction
  | UserMgtResetPasswordAction
  | UserMgtNewUserPasswordAction
  | UserMgtResetPasswordSuccessAction
  | UserMgtResetPasswordFailAction
  | UserMgtNoopAction;
