import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RspndrConfig } from '../@models/common';
import { RspndrPage, RspndrResponse } from '../@models/rspndr';

export interface CrudApi<T, C> {
  create(entity: T): Observable<T>;

  update(entity: T): Observable<T>;

  get(id: string): Observable<T>;

  search(page: RspndrPage<C, T>): Observable<RspndrResponse<RspndrPage<C, T>>>;

  remove(id: string): Observable<string>;
}

export abstract class BaseCrudApi<T, C> implements CrudApi<T, C> {
  constructor(
    protected readonly basePath: string,
    protected config: RspndrConfig,
    protected http: HttpClient,
  ) {}

  create(entity: T): Observable<T> {
    return this.http.post<T>(this.config.baseUrl + this.basePath, entity);
  }

  update(entity: T): Observable<T> {
    return this.http.put<T>(this.config.baseUrl + this.basePath, entity);
  }

  get(id: string): Observable<T> {
    return this.http.get<T>(`${this.config.baseUrl + this.basePath}/by/${id}`);
  }

  search(page: RspndrPage<C, T>): Observable<RspndrResponse<RspndrPage<C, T>>> {
    // eslint-disable-next-line no-param-reassign
    page.pageNumber = page.pageNumber || 0;
    // eslint-disable-next-line no-param-reassign
    page.pageSize = page.pageSize || 100;

    return this.http.post<RspndrResponse<RspndrPage<C, T>>>(
      `${this.config.baseUrl + this.basePath}/search`,
      page,
    );
  }

  remove(id: string): Observable<string> {
    return this.http.delete<string>(`${this.config.baseUrl + this.basePath}/by/${id}`);
  }
}
