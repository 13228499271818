import { ActionReducer } from '@ngrx/store';
import { APP_ACTION_TYPES } from '../app.actions';
import { defaultState, State } from './reducers';

export function clearState(reducer: ActionReducer<State>): ActionReducer<State> {
  // eslint-disable-next-line func-names
  return function (state, action) {
    let newState = { ...state };
    if (action.type === APP_ACTION_TYPES.LOGOUT) {
      newState = { ...defaultState };
    }
    return reducer(newState, action);
  };
}
