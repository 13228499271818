import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MultiPolygon } from 'geojson';
import { RspndrConfig } from '../@models/common';
import {
  RspndrPage,
  RspndrResponse,
  RspndrUtilQueryVersionCriteria,
  RspndrUtilQueryVersion,
  RspndrGeoFence,
} from '../@models/rspndr';

@Injectable()
export class RspndrUtilApi {
  constructor(private config: RspndrConfig, private http: HttpClient) {}

  searchGuardVersion(page: RspndrPage<RspndrUtilQueryVersionCriteria, RspndrUtilQueryVersion>) {
    return this.http.post<
      RspndrResponse<RspndrPage<RspndrUtilQueryVersionCriteria, RspndrUtilQueryVersion>>
    >(`${this.config.baseUrl}/api/util/guard/version`, page);
  }

  searchDeviceVersion(page: RspndrPage<RspndrUtilQueryVersionCriteria, RspndrUtilQueryVersion>) {
    return this.http.post<
      RspndrResponse<RspndrPage<RspndrUtilQueryVersionCriteria, RspndrUtilQueryVersion>>
    >(`${this.config.baseUrl}/api/util/device/version`, page);
  }

  getGeofences(): Observable<RspndrGeoFence<MultiPolygon>[]> {
    return this.http.get<RspndrGeoFence<MultiPolygon>[]>(`${this.config.baseUrl}/v2/geofence`);
  }
}
