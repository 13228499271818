import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseCrudApi } from './crud';
import { RspndrConfig } from '../@models/common';
import {
  RspndrOrganisation,
  RspndrOrganisationFilterCriteria,
  RspndrInvoicingModel,
} from '../@models/rspndr';

@Injectable()
export class RspndrOrgApi extends BaseCrudApi<
  RspndrOrganisation,
  RspndrOrganisationFilterCriteria
> {
  constructor(protected config: RspndrConfig, protected http: HttpClient) {
    super('/api/orgs', config, http);
  }

  getOrganisationInvoicingModel(tenantId: string): Observable<RspndrInvoicingModel> {
    return this.http.get<RspndrInvoicingModel>(
      `${this.config.baseUrl}/api/orgs/${tenantId}/invoicingmodel`,
    );
  }
}
