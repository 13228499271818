import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';
import { Injectable } from '@angular/core';

@Injectable()
export class FirebaseService {
  fbConfig: any;
  fbRemoteConfigRef: any;

  constructor() {
    this.fbConfig = {
      apiKey: 'AIzaSyBWGEDnC2CiSBZAIu44tLr-5k6AuWsb0B4',
      projectId: 'rspndr-8f835',
      appId: '1:461135190117:web:a8233f34702cf3a06d4e8c',
    };
  }

  init() {
    if (!!this.fbConfig) {
      firebase.initializeApp(this.fbConfig);

      this.fbRemoteConfigRef = firebase.remoteConfig();
      this.fbRemoteConfigRef.defaultConfig = {
        rspndr_legacy_portal_version: '0.0.0',
      };
      this.fbRemoteConfigRef.settings = {
        minimumFetchIntervalMillis: 21600000,
        fetchTimeoutMillis: 21600000,
      };
    }
  }

  fetchRemoteConfig(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fbRemoteConfigRef
        .fetchAndActivate()
        .then(() => {
          resolve(this.fbRemoteConfigRef.getValue('rspndr_legacy_portal_version'));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
