import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RxjsUtils {
  constructor(private snackbar: MatSnackBar) {}

  pollingBackoffStrategy({
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = [],
    requestType = 'data',
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
    requestType?: string;
  } = {}) {
    return (attempts: Observable<any>) =>
      attempts.pipe(
        mergeMap((error, i) => {
          const retryAttempt = i + 1;
          // if maximum number of retries have been met
          // or response is a status code we don't wish to retry, throw error
          if (
            retryAttempt > maxRetryAttempts ||
            excludedStatusCodes.find((e) => e === error.status)
          ) {
            return throwError(() => error);
          }
          const nextRetryInterval = 2 ** (1 + retryAttempt) * scalingDuration;
          this.snackbar.open(
            `Failed to load ${requestType} (${retryAttempt}/${maxRetryAttempts}) - trying again in ${
              nextRetryInterval / 1000
            } seconds.`,
            'OK',
            {
              panelClass: 'snackbar-warning',
              duration: nextRetryInterval,
            },
          );
          // retry after 1s, 4s, 9s...
          return timer(nextRetryInterval);
        }),
      );
  }
}
