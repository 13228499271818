import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { AuthorityDirective } from './authority';
import { FileDropDirective } from './file-drop.directive';

@NgModule({
  declarations: [AuthorityDirective, FileDropDirective],
  imports: [MomentModule],
  exports: [AuthorityDirective, FileDropDirective],
})
export class DirectivesModule {}
